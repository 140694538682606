@font-face {
  font-family: Lato;
  src: url("../../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 23vw;
  background-color: #ffffff;
  border: none;
  overflow-y: auto;
  font-family: Lato;
  z-index: 2;
}

.Modal:focus {
  outline: 0;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 2;
}

.container {
  height: 100%;
  width: 100%;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* padding: 30px 0; */
  position: relative;
  padding: 20px 0px;
}

.actualText {
  text-align: center;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  height: 35px;
  width: 35px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.closeButton:focus {
  outline: none;
}

.btnDiv {
  margin-top: 3vh;

  /* width: 30%;
  background-color: blue; */
}

.btn {
  padding: 10px 20px;
  outline: none !important;
  border-radius: 5px;
  background-color: #406bdf;
  color: #fff;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  border: none;
  transition: all ease-out 0.3s;
}

@media (max-width: 1630px) {
  .Modal {
    width: 30vw;
  }
}

@media (max-width: 1280px) {
  .Modal {
    width: 40vw;
  }
}

@media (max-width: 1024px) {
  .Modal {
    width: 50vw;
  }
}

@media (max-width: 768px) {
  .Modal {
    width: 70vw;
  }
}

@media (max-width: 576px) {
  .Modal {
    width: 90vw;
  }
}
