.modalBtns {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.LogInBtn {
  padding: 0 15%;
  flex: 1;
}
.SignupBtn {
  padding: 0 5%;

  flex: 1;
}

@media (max-width: 1210px) {
  .modalBtns {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 0% 0%;
  }
  .LogInBtn {
    padding: 1% 0;
    margin: 10px 0px;
  }
  .SignupBtn {
    padding: 1% 0;
    width: 120px;
    font-size: 13px;
  }
}
