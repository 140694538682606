@font-face {
  src: url("../../Assets/Fonts/Lato-Regular.ttf");
  font-family: Lato;
  font-display: swap;
}

@font-face {
  src: url("../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-family: sspb;
  font-display: swap;
}

.container {
  display: flex;
  margin: 3px 0px;
  width: 100%;
  flex-direction: row;
}
.col {
  border: solid 1px #c6cbde;
  padding: 4px 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.on {
  background-color: #ffffff;
}
.off {
  background-color: #f8f8f8;
}
.col1 {
  flex: 4;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.questionImage {
  height: 10px;
  width: 10px;
  cursor: pointer;
}
.col2 {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: sspb;
  padding-right: 10px;
}
.col3 {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sspb;
}
.col4 {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: sspb;
  padding-right: 10px;
}
.catTitle {
  font-family: Lato;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.12px;
  color: #2b2d38;
  width: 80%;
}
.catImage {
  width: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.cell2Text {
  font-family: sspb;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.12px;
  text-align: left;
  color: #404a57;
}
.inputVal {
  font-family: sspb;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.12px;
  text-align: right;
  color: #404a57;
  width: 70px;
  height: 24px;
  border-radius: 5px;
  border: solid 1px #c6cbde;
  background-color: #ffffff;
  padding-right: 5px;
}
