@font-face {
  font-family: Lato;
  src: url("../../Assets//Fonts//Lato-Regular.ttf");
  font-display: swap;
}

/* Button to login from Fees */
.fromFeesBtn {
  background-color: transparent;
  color: #406bdf;
  text-decoration: underline;
  cursor: pointer;
  border: 0;
  outline: 0;
}
.fromFeesBtn:hover {
  color: #406bdf;
  text-decoration: underline;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 23vw;
  background-color: #ffffff;
  border: none;
  z-index: 2;
}

.Modal:focus {
  outline: 0;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 2;
}

.loginButton {
  width: 119px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  transition: all 0.4s ease-out;
  background-color: #ffffff;
  border: 1px solid #406bdf;
  color: #406bdf;
}

.loginButton:hover {
  background-color: #406bdf;
  color: #ffffff;
}

.loginButton:focus {
  outline: none;
}

h3 {
  margin: 0;
  padding: 0;
}

.heading {
  font-family: Lato;
  text-align: center;
  padding: 30px 0 0 0px;
}

.headingContainer {
  padding: 20px 0px;
}

.belowHeader {
  color: #797c8a;
  font-size: 18px;
  text-align: center;
  font-family: Lato;
}

.closeContainer {
  position: relative;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.closeButton:focus {
  outline: none;
}

@media (max-width: 1630px) {
  .Modal {
    width: 30vw;
  }
}

@media (max-width: 1280px) {
  .Modal {
    width: 40vw;
  }
}

@media (max-width: 1024px) {
  .Modal {
    width: 50vw;
  }
}

@media (max-width: 768px) {
  .Modal {
    width: 70vw;
  }
  .crossButton {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 576px) {
  .Modal {
    width: 90vw;
    height: 94vh;
    max-height: 650px;
    overflow: scroll;
  }
}
