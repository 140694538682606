@font-face {
  font-family: sspb;
  src: url('../../../Assets//Fonts/SourceSansPro-Semibold.otf');
}

.toolTip {
  color: #535353;
  border-radius: 5px;
  border: 1px solid rgb(97, 97, 97);
  background-color: white;
  padding: 5px;
  font-size: 13px;
  font-family: sspb;
  pointer-events: none !important;
}

.chartCont {
  height: 100%;
}

.tooltip {
  pointer-events: none !important;
}


@media(max-width:576px)
{
  .toolTip{
    max-width: 100px;
  }
}