.wrapper {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.desc {
  font-size: 14px;
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
}

.support {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.legal {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.links {
  margin-left: -10px;
  display: flex;
  flex-direction: row;
  text-align: center;
}

.link {
  padding: 2px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  align-self: center;
  max-height: 40px;
}
.link:hover {
  text-decoration: none;
  font-weight: 500;
  color: black;
}
.link + .link {
  padding: 5px;
  border-left: 1px solid #a0a1a7;
}

.head {
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  .support {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .legal {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 768px) {
  .links {
    margin-left: -5px;
  }

  .link {
    padding: 5px;
    text-decoration: none;
    color: black;
    cursor: pointer;
  }


  .legal {
    flex: 1;
  }
}

@media (max-width: 576px) {
  .wrapper {
    width: 95%;
  }
  .bottom {
    flex-direction: column;
  }
  .support {
    margin-bottom: 15px;
  }
  .links {
    font-size: 14px;
  }
}
