.gapper {
  margin-top: 10vh;
}
.main {
  margin-top: 50px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.loader {
  height: 5vh;
  align-self: center;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px 0 0;
  margin-top: 50px;
}
.img {
  width: 45vw;
}
.philoH1 {
  font-size: 40px;
}
.philoP {
  font-size: 18px;
}
.philoH2 {
  font-size: 34px;
  margin-bottom: 16px !important;
  margin-top: 5px;
}

.textContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 7vw;
  flex: 1;
}
.loginButton {
  width: 180px;
  height: 45px;
  border-radius: 10px;
  background-color: #406bdf;
  color: white;
  border: none;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  align-self: center;
}

.mainphilosophy {
  margin-top: 5vh;
  margin-left: 12vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.ulTag ul {
  list-style-type: none;
  padding: 0px;
}
.ulItem {
  position: relative;
  z-index: -2;
}
.square {
  width: 9px;
  height: 9px;
  background-color: #3d86ef;
  top: 10px;
  position: absolute;
}
.squareP {
  width: 2%;
  display: inline-block;
}
.ptagP {
  width: 82%;
  display: inline-block;
}
.ulH4 {
  display: inline-block;
}

.ulTag ul h4 {
  color: #416ce1;
}
.ulTag ul p {
  font-size: 16px;
}
.carouselIndicators li {
  background-color: #416ce1;
}
@media (max-width: 575px) {
  .mainphilosophy {
    margin-left: 10px;
    align-content: center;
  }
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    height: 77vh;
  }
  .img {
    margin: 0 auto;
    width: 100%;
    display: block;
    margin-bottom: 22px;
    margin-top: 18px;
    float: none;
  }
  ul h4 {
    font-size: 19px;
  }
  .ulTag ul p {
    font-size: 14px;
  }
  .ulTag ul h4 {
    font-size: 19px;
  }
  .ptagP {
    width: 92%;
    margin-left: 9px;
  }
  .philoH1 {
    font-size: 32px;
    text-align: center;
  }
  .philoH2 {
    font-size: 27px;
    margin-bottom: 16px !important;
  }
  .philoP {
    font-size: 17px;
    text-align: center;
    width: 95%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .mainphilosophy {
    align-content: center;
  }
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .img {
    margin: 0 auto;
    width: 500px;
    display: block;
    margin-bottom: 22px;
    margin-top: 18px;
    float: none;
  }
  .philoH1 {
    font-size: 35px;
    text-align: center;
  }
  .philoH2 {
    font-size: 30px;
    margin-bottom: 16px !important;
  }
  .ulTag ul h4 {
    font-size: 21px;
  }
  .philoP {
    font-size: 17px;
    text-align: center;
  }
  .button {
    width: 165px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1122px) {
  .img {
    width: 585px;
  }
}
