@font-face {
  font-family: ssp;
  src: url("../../Assets//Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.inputField {
  width: 100%;
  border-radius: 20px;
  font-family: ssp;
  outline: none;
  box-sizing: border-box;
  height: 40px;
  border: solid 1px #416ce1;
  background-color: #ffffff;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  transition: box-shadow 0.3s;
  font-size: 15px;
  border: none;
  background-color: transparent;
  outline: none;
}

.inputCont {
  flex: 1;
  padding-left: 5px;
}

.hideDiv {
  display: none;
}

.searchWrapper {
  overflow: visible;
  width: 95%;
}

.dropDownDiv {
  position: relative;
  width: 100%;
  z-index: 1000;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noMatchFound {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #404a57;
}

.list {
  background-color: white;
  position: absolute;
  width: 100%;
  padding: 10px 0 10px 0;
  list-style: none;
  margin: 0;
  border: solid 1px #416ce1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(32, 33, 36, 0.28);
}

.listItem {
  text-decoration: none;
  font-size: 12px;
  border-bottom: 1px solid #ededed;
  color: #404a57;
  cursor: pointer;
}

.listItem:hover {
  background-color: #e9e9e9;
}

.innerDiv {
  padding: 10px 0px 10px 50px;
}

.item {
  border-top: 1px solid #f5f5f5;
}

.inputWithIcon {
  display: flex;
  height: 40px;
  max-height: 50px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.096);
  border: solid 1px #416ce1;
  background-color: #ffffff;
  border-radius: 25px;
  outline: none;
  align-items: center;
  padding: 0px 10px;
  margin: 8px 0 0 0;
  box-sizing: border-box;
}

.inputWithIcon .inputIcon {
  color: #416ce1;
  transition: 0.3s;
}

.inputWithIcon .inputField:focus + .inputIcon {
  color: #416ce1;
}

.inputWithIcon.inputIconBg .inputIcon {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

@media (max-width: 576px) {
  .inputField {
    width: 100%;
    border-radius: 20px;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    height: 40px;
    font-size: 14px;
  }
}
