.container {
  display: flex;
  width: 100%;
  height: 100%;
}
.gapper {
  margin-top: 10vh;
}
.main {
  margin: 0;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.searchParent {
  width: 90%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-self: center;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #31333e;

  margin-top: 31px;
}

.inputWithIcon {
  display: flex;
  height: 40px;
  max-height: 50px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.096);
  border: solid 1px #416ce1;
  background-color: #ffffff;
  border-radius: 25px;
  outline: none;
  align-items: center;
  padding: 0px 10px;
  margin: 8px 0 0 0;
  box-sizing: border-box;
}

.stockGain {
  max-height: 55vh;
  overflow-y: scroll;

  overflow-x: hidden;
}

.inputWithIcon .inputIcon {
  color: #416ce1;
  transition: 0.3s;
}

.inputWithIcon .inputField:focus + .inputIcon {
  color: #416ce1;
}

.inputWithIcon.inputIconBg .inputIcon {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

.inputField {
  width: 100%;
  border-radius: 20px;
  font-family: ssp;
  outline: none;
  box-sizing: border-box;
  height: 40px;
  border: solid 1px #416ce1;
  background-color: #ffffff;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  transition: box-shadow 0.3s;
  font-size: 15px;
  border: none;
  background-color: transparent;
  outline: none;
}

.inputCont {
  flex: 1;
  padding-left: 5px;
}
.msgBox {
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #a3b9f5;
  padding: 20px 10px;
  color: #7c7c7c;
  background-color: white;
  border-radius: 6px;
  justify-content: center;
  text-align: center;
}

.msgBoxCont {
  height: 100%;
  width: 92%;
  padding: 20px;
  margin-left: 60px;
  align-self: center;
  padding-top: 40px;
  align-items: center;
  justify-content: center;
}

.msgBoxText {
  padding-right: 5px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1px;
}
.msgBoxText1 {
  margin-top: 22px;
  padding-right: 5px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1px;
}
.crypto {
  grid-area: crypto;
  min-height: 600px;
  max-height: 750px;
}
.cryptoStock {
  grid-area: crypto;
  min-height: 600px;
}
.cryptoMover {
  flex-direction: column;
  width: 870px;
  cursor: pointer;
  max-height: 390px;
  overflow: hidden;
  border: 0.5px solid #cccdce;
  margin-left: 25px;
  margin-top: 25px;
  border-radius: 5px;
  padding-bottom: 30px;
  justify-content: center;
}
.cryptoMover1 {
  flex-direction: column;
  width: 1310px;
  min-height: 280px;
  overflow: hidden;
  border: 0.5px solid #cccdce;
  margin-left: 15px;
  margin-top: 25px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;

  justify-content: center;
  align-items: center;
}
.cryptoMover2 {
  flex-direction: column;
  width: 610px;
  min-height: 330px;
  overflow: hidden;
  border: 0.5px solid #cccdce;
  margin-left: 0px;
  margin-top: 25px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  padding-bottom: 30px;
  justify-content: center;
}
.moversTitleStock {
  font-size: 15px;

  font-weight: 700;
  line-height: 1px;
}

.stockSentiRange{

  font-size: 11px;
  color: gray;
  text-align: center;
}
.moversTitle1 {
  margin-left: 15px;
}
.moversTitleStock1 {
  font-size: 15px;
  margin-right: -80px;
  font-weight: 700;
  line-height: 1px;
}
.moversTitleStock2 {
  font-size: 15px;
  margin-right: -90px;
  font-weight: 700;
  line-height: 1px;
}

.moversTitleStockCenter {
  font-size: 15px;
  position: absolute;
  left: 332px;
  top: 19px;
  font-weight: 500;
  line-height: 1px;
}

.moversTitleStockLeft {
  display: flex;

  position: absolute;
  left: 2px;
  top: 9px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
}
.moversTitleStockRight {
  font-size: 15px;
  margin-right: 40px;
  position: absolute;
  right: 32px;
  top: 19px;
  font-weight: 500;
  line-height: 1px;
  margin-right: -10px;
}

.moversTitle {
  font-size: 15px;

  font-weight: 700;
  line-height: 1px;
}
.title {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 20px;
  border-bottom: 0.5px solid #c7c6c6;
}
.name {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: 11px;
}

.title1 {
  margin-top: -100px;
  display: flex;
  padding: 20px;
  margin-left: -20px;
}
.name1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 21px;
}

.mytestHeadercry {
  font-size: 25px;
  color: black;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -105px;
  display: flex;
  justify-content: center;
}

.feesTextcry {
  width: 80%;
  padding: 0 10px;
  margin: 0;
}

.selectionTabs {
  display: flex;
  flex-direction: row;
  max-height: 53vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.cryptoGainScroll {
  flex-direction: column;
}
.moverSelection {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  width: 742px;
  height: 58px;

  align-items: center;
  justify-content: space-between;

  background-color: #dbe4fd;
  cursor: pointer;
}
.moverSelectionWithout {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 742px;
  height: 58px;

  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  cursor: pointer;
}

.moverSelectionStock {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 58px;

  align-items: center;
  justify-content: space-between;

  background-color: #dbe4fd;
  cursor: pointer;
}
.moverSelectionWithoutStock {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 58px;

  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  cursor: pointer;
}

.tickerRow {
  width: 180px;
  display: flex;
  flex-direction: row;
  margin-left: 12px;
  margin-right: -65px;
}

.tickerRowStock {
  width: 180px;
  display: flex;
  flex-direction: row;
  margin-left: 12px;
  margin-right: -70px;
}
.tickerRowStock1 {
  width: 180px;
  display: flex;
  flex-direction: row;
  margin-left: 12px;
  margin-right: -70px;
}

.speedometerView {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 75px;
  justify-content: center;
}

.signupBtn {
  width: 139px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #406bdf;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: 1px solid #406bdf;
  transition: all 0.4s ease-out;
  margin-top: 30px;
  margin-left: 40%;
}

.mainCard {
  min-height: 90%;
  width: 1380px;
  padding: 20px;
  display: flex;
  align-self: center;
  align-self: center;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  margin-left: 60px;
  margin-top: 10px;
}

.mainCard2 {
  min-height: 90%;
  width: 1420px;
  padding: 20px;
  display: flex;
  align-self: center;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  margin-left: 70px;
  margin-top: -40px;
}

.signupBtnAd {
  width: 139px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #406bdf;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: 1px solid #406bdf;
  transition: all 0.4s ease-out;
  margin-left: 30px;
}

.signupBtn:hover {
  background-color: #ffffff;
  color: #406bdf;
}

.signupBtn:focus {
  outline: none;
}

.selectLightText {
  text-transform: uppercase;
  color: rgb(165, 161, 161);
  font-size: 11px;
  margin-left: 10px;
  margin-right: 10px;
}
.selectLightTextGreen {
  text-transform: uppercase;
  color: rgb(12, 201, 5);
  font-size: 11px;
  margin-left: 5px;
  margin-top: 3px;
}
.selectLightTextRed {
  text-transform: uppercase;
  color: rgb(255, 30, 0);
  font-size: 11px;
  margin-left: 5px;
  margin-top: 3px;
}
.selectLightTextGreenC {
  text-transform: uppercase;
  color: rgb(12, 201, 5);
  font-size: 11px;
  margin-left: 3px;
  margin-top: 3px;
}
.selectLightTextRedC {
  text-transform: uppercase;
  color: rgb(255, 30, 0);
  font-size: 11px;
  margin-left: 3px;
  margin-top: 3px;
}

.stockCard {
  height: 45px;
  cursor: pointer;
  width: 540px;
  padding: 20px;
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  align-self: center;
  border: 1px solid #406bdf;
  flex-direction: row;
  position: relative;
  justify-content: center;
}
.stockCardWithout {
  height: 45px;
  width: 540px;
  padding: 20px;
  margin-left: 30px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  align-self: center;
  border: 1px solid #8b8a8a;
  flex-direction: row;
  position: relative;
  justify-content: space-evenly;
}

.myStockHeadercry {
  font-size: 20px;
  color: #406bdf;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -105px;
  display: flex;
  justify-content: center;
}

.stockTextcry {
  width: 80%;
  padding: 0 10px;
  margin: 0;
}
.stockTickerText {
  font-size: 13px;
}

.stock {
  grid-area: crypto;
  min-height: 600px;
  max-height: 750px;
  margin-top: -50px;
}

.stockTitle {
  align-items: center;

  display: flex;
  padding: 20px;
}
.stockName {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: 11px;
}

.stockNameUpper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: 11px;
}

.stockCardDetail {
  display: flex;
  flex-direction: column;
  width: 130px;
  height: 50px;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 7px;
  padding-left: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #afafaf;
}

.selectLightTextRow {
  display: flex;
  flex-direction: row;
}

.selectDarkText {
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px;
}
.selectDarkTextCur {
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px;
}
.selectGreyText {
  font-weight: 300;
  color: rgb(99, 96, 96);
  font-size: 11px;
  margin-left: 4px;
  margin-top: 9px;
}
.selectGrayText {
  font-weight: 300;
  color: rgb(99, 96, 96);
  font-size: 11px;
  margin-left: 9px;
  margin-top: 6px;
}
.selectDarkTexts {
  font-weight: 700;
  font-size: 11px;
}
.overallTitle {
  margin-top: 10px;
  display: flex;
  padding: 20px;
  margin-left: -20px;
}
.overallName {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 21px;
}
.selectionTabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.selectionTabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.stockOverall {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.SellCont {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.sellBtn {
  width: 60px;
  height: 30px;
 border: 1px solid gray;
  margin-left: 15px;
  margin-top: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}
.sellBtnTxt {
  align-self: center;
  margin-top: 2px;
  color: gray;
  margin-left: 15px;
}
.sellBtnTxtOverall {
  align-self: center;
  margin-top: 2px;
  margin-left: 15px;
  border: 1px solid gray;
  text-align: center;
  width: 35px;
  font-size: 17px;
  border-radius: 5px;
  height: 30px;
  font-weight: 600;
}

.buyBtn {
  width: 60px;
  height: 30px;
  border: 1px solid gray;
  margin-right: 30px;
  margin-top: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.buyBtnTxt {
  align-self: center;
  color: gray;
  margin-top: 2px;
  margin-left: 15px;
}

.speedBottom {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-left: -50px;
}
.speedTitle {
  margin-top: -100px;
  display: flex;
  padding: 20px;
  margin-left: 30px;
}
.speedTitle1 {
  margin-top: -100px;
  display: flex;
  padding: 20px;
  margin-left: 80px;
}
.speedSellCont {
  display: flex;
  flex: 1;
  width: 400px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -30px;
}

.speedSellCont1 {
  display: flex;
  flex: 1;
  width: 400px;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -50px;
  margin-top: -30px;
}

.speedSellContUpper {
  display: flex;
  flex: 1;
  width: 480px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -40px;
}
.speedSellContUpperRange {
  display: flex;
  flex: 1;
  width: 360px;
  margin-left: 38px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 185px;
  position: absolute;
}
.speedSellContUpperRange1 {
  display: flex;
  flex: 1;
  width: 310px;
  margin-left: 38px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 115px;
  position: absolute;
}
.speedSellContUpperRange2 {
  display: flex;
  flex: 1;
  width: 310px;
  margin-left: -10px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 115px;
  position: absolute;
}
.speedSellContUpperRange3 {
  display: flex;
  flex: 1;
  width: 430px;
  margin-left: -10px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
  position: absolute;
}
.speedSellBtn {
  width: 40px;
  height: 25px;
  border: 1px solid gray;

  margin-left: 15px;
  margin-top: 10px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.speedSellBtnTxt {
  align-self: center;
  margin-top: 0px;
  color: gray;
  margin-left: 7px;
}

.speedBuyBtn {
  width: 40px;
  height: 25px;
  border: 1px solid gray;
  margin-right: 30px;
  margin-top: 10px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.speedBuyBtnTxt {
  align-self: center;
  margin-top: 0px;
  color: gray;
  margin-left: 7px;
}

.stockNameUpper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: 11px;
}
.stockNameUpper1 {
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
  flex-direction: row;
  margin-right: 90px;
  align-items: center;
  justify-content: space-evenly;
  font-size: 11px;
}

@media (max-width: 590px) {
  .selectGrayText {
    font-weight: 300;
    color: rgb(99, 96, 96);
    font-size: 9px;
    text-align: left;
    margin-left: -10px;
    margin-top: 1px;
    padding-right: 10px;
  }

  .speedSellContUpperRange {
    display: flex;
    flex: 1;
    width: 300px;
    margin-left: -20px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 195px;
    position: absolute;
  }
  .speedSellContUpperRange1 {
    display: flex;
    flex: 1;
    width: 310px;
    margin-left: -10px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 115px;
    position: absolute;
  }
  .speedSellContUpperRange2 {
    display: flex;
    flex: 1;
    width: 350px;
    margin-left: -25px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -35px;
    position: absolute;
  }

  .msgBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #7291e7;
    align-self: center;
    color: #474747;
    background-color: white;
    border-radius: 6px;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
  }

  .msgBoxCont {
    height: 140px;
    width: 100%;
    padding: 10px;
    align-self: center;
    padding-top: 40px;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }

  .msgBoxText {
    padding-right: 5px;
    font-size: 11px;
    font-weight: 700;
  }
  .msgBoxText1 {
    margin-top: 22px;
    padding-right: 5px;
    font-size: 11px;
    font-weight: 700;
    line-height: 1px;
  }

  .name {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 11px;
  }

  .title1 {
    margin-top: -100px;
    display: flex;
    padding: 20px;
    margin-left: -20px;
  }
  .name1 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 21px;
  }

  .mytestHeadercry {
    font-size: 25px;
    color: black;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    align-self: center;
    display: flex;
    margin-left: 20px;
    justify-content: center;
  }

  .feesTextcry {
    width: 80%;
    padding: 0 10px;
    margin: 0;
  }
  .selectionTabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .moverSelection {
    display: flex;
    flex-direction: column;
    width: 110px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 5px;
    align-items: center;
    justify-content: left;
    border: 2px solid #416ce1;
    background-color: #dbe4fd;
    cursor: pointer;
  }
  .moverSelectionWithout {
    display: flex;
    flex-direction: column;
    width: 110px;
    height: 35px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    border-radius: 7px;
    align-items: left;
    justify-content: left;
    border: 1px solid #afafaf;
    cursor: pointer;
  }

  .speedometerView {
    margin-top: 130px;
    margin-left: 15px;
  }

  .signupBtn {
    width: 129px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #406bdf;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: 1px solid #406bdf;
    transition: all 0.4s ease-out;
    margin-top: 30px;
    margin-left: 8%;
  }
  .moversTitle {
    padding-right: 15px;
    font-size: 15px;
    padding-left: 15px;
    font-weight: 700;
    line-height: 1px;
  }

  .speedSellContUpper {
    display: flex;
    flex: 1;
    width: 320px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -50px;
    margin-left: -15px;
  }

  .speedSellBtn {
    width: 40px;
    height: 25px;
    border: 1px solid gray;

    margin-left: 15px;
    margin-top: 10px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  }
  .speedSellBtnTxt {
    align-self: center;
    color: gray;
    margin-top: 0px;
    font-size: 14px;
    margin-left: 7px;
  }

  .speedBuyBtn {
    width: 40px;
    height: 25px;
    border: 1px solid gray;

    margin-right: 30px;
    margin-top: 10px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  }

  .speedBuyBtnTxt {
    align-self: center;
    margin-top: 0px;
    color: gray;
    margin-left: 7px;
    font-size: 14px;
  }
  .crypto {
    flex-direction: column;
    grid-area: crypto;
    align-self: center;
    align-items: center;
    margin-left: 0px;
  }

  .mainCard {
    height: 900px;
    width: 88%;
    padding: 20px;
    display: flex;
    align-self: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    margin-left: 25px;
    margin-bottom: 30px;
  }

  .cryptoMover {
    flex-direction: column;
    width: 100%;
    height: 280px;

    border: 0.5px solid #cccdce;
    margin-left: 1px;
    margin-top: 15px;
    border-radius: 5px;

    justify-content: center;
  }

  .moversTitle {
    padding-right: 5px;
    font-size: 15px;
    font-weight: 700;
    line-height: 1px;
  }
  .title {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px;
    border-bottom: 0.5px solid #c7c6c6;
  }
  .name {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 11px;
  }

  .title1 {
    margin-top: -100px;
    display: flex;
    padding: 20px;
    margin-left: -20px;
  }
  .name1 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 21px;
  }

  .stockNameUpper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 9px;
  }
  .stockCardDetail {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 40px;
    margin-top: 20px;
    border-radius: 7px;

    border: 1px solid #afafaf;
  }
  .selectLightText {
    text-transform: uppercase;
    color: rgb(165, 161, 161);
    font-size: 9px;
    margin-left: -8px;
    margin-right: 0px;
  }
  .selectLightTextGreen {
    text-transform: uppercase;
    color: rgb(12, 201, 5);
    font-size: 9px;
  }
  .selectLightTextRed {
    text-transform: uppercase;
    color: rgb(255, 30, 0);
    font-size: 9px;
  }
  .selectLightTextGreenC {
    text-transform: uppercase;
    color: rgb(12, 201, 5);
    font-size: 9px;
    margin-top: -12px;
    margin-left: 60px;
  }
  .selectLightTextRedC {
    text-transform: uppercase;
    color: rgb(255, 30, 0);
    font-size: 9px;
    margin-top: -12px;
    margin-left: 60px;
  }
  .selectDarkTexts {
    font-weight: 600;
    font-size: 9px;
    margin-left: -3px;
  }
  .selectDarkText {
    font-weight: 300;
    font-size: 11px;
    text-align: left;
    margin-top: 2px;
  }

  .speedSellContUpper {
    display: flex;
    flex: 1;
    width: 340px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -40px;
    margin-left: -30px;
  }

  .cryptoStock {
    display: flex;
    flex-direction: column;
    grid-area: crypto;
    align-self: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 250px;
  }
  .stockMobTitle {
    justify-content: center;
    width: 110%;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    display: flex;
    margin-top: 30px;
    margin-left: 5px;
  }

  .mobmoversTitle {
    font-size: 15px;
    width: 180px;
    height: 40px;
    font-weight: 700;
    color: #3781f0;
    text-align: center;
    border-bottom: 3px solid #3781f0;
  }
  .mobmoversTitleWithout {
    font-size: 15px;
    width: 180px;
    height: 40px;
    font-weight: 700;

    text-align: center;
    border-bottom: 3px solid #616161;
  }
  .mobmoversTitle2 {
    font-size: 15px;
    width: 180px;
    color: #3781f0;
    height: 40px;
    font-weight: 700;
    text-align: center;
    border-bottom: 3px solid #3781f0;
    margin-left: -50px;
  }
  .mobmoversTitle2Without {
    font-size: 15px;
    width: 180px;

    height: 40px;
    font-weight: 700;
    text-align: center;
    border-bottom: 3px solid #696969;
    margin-left: -50px;
  }
  .cryptoMover2 {
    flex-direction: column;
    width: 370px;
    min-height: 330px;
    overflow: hidden;
    border: 0.5px solid #cccdce;
    margin-left: 0px;
    margin-top: 25px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    padding-bottom: 30px;
    justify-content: center;
  }

  .mobStocktitle {
    justify-content: center;
    align-items: center;

    display: flex;
    padding: 20px;
    border-bottom: 0.5px solid #c7c6c6;
  }
  .mobStockname {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    margin-left: 20px;
  }
  .stockCard {
    height: 45px;
    width: 340px;
    padding: 20px;
    margin-left: 18px;
    margin-top: 20px;
    display: flex;
    align-self: center;
    border: 1px solid #406bdf;
    flex-direction: row;
    position: relative;
    justify-content: center;
  }
  .stockCardWithout {
    height: 45px;
    width: 340px;
    padding: 20px;
    margin-left: 18px;
    margin-top: 20px;
    display: flex;
    align-self: center;
    border: 1px solid #8b8a8a;
    flex-direction: row;
    position: relative;
    justify-content: space-evenly;
  }
  .moversTitleStock1 {
    font-size: 15px;
    margin-right: -50px;
    font-weight: 700;
    line-height: 1px;
  }
  .moversTitleStock2 {
    font-size: 15px;
    margin-right: -5px;
    font-weight: 700;
    line-height: 1px;
  }

  .moversTitleStockCenter {
    font-size: 15px;
    position: absolute;
    left: 192px;
    top: 19px;
    font-weight: 500;
    line-height: 1px;
  }

  .moversTitleStockLeft {
    display: flex;

    position: absolute;
    left: -10px;
    top: 9px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
  }
  .moversTitleStockRight {
    font-size: 15px;
    margin-right: 1px;
    position: absolute;
    right: 32px;
    top: 19px;
    font-weight: 500;
    line-height: 1px;
  }
  .myStockHeadercry {
    font-size: 20px;
    color: #406bdf;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: -20px;
    display: flex;
    font-weight: 700;
    justify-content: center;
  }

  .stockTextcry {
    width: 80%;
    padding: 0 10px;
    margin: 0;
  }
  .stockNameUpperMob {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 9px;
    margin-left: 15px;
    margin-top: -20px;
  }
  .cryptoMover2 {
    flex-direction: column;
    width: 370px;
    min-height: 330px;
    overflow: hidden;
    border: 0.5px solid #cccdce;
    margin-left: 0px;
    margin-top: 25px;
    margin-bottom: 60px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    padding-bottom: 30px;
    justify-content: center;
  }
  .speedometerViewMob {
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    margin-left: 35px;
    justify-content: center;
  }
  .stockOverall {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
}
