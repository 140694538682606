@font-face {
  font-family: ssp;
  src: url("../../Assets//Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.err {
  font-family: ssp;
  font-size: 15px;
}
