.container {
  display: flex;
  flex-direction: row;
}

.btn {
  border: 0;
  border: solid 0.3px #e5e5e5;
  background-color: #416ce1;
  font-family: Lato;
  text-align: center;
  color: #ffffff;
  font-size: 13px;
  width: 120px;
  height: 32px;
  border-radius: 3px;
}
.btn:focus {
  outline: 0;
}

.dead {
  border: 0;
  border: solid 0.3px #e5e5e5;
  background-color: #dadada;
  font-family: Lato;
  text-align: center;
  color: #6a717b;
  font-size: 13px;
  width: 120px;
  height: 32px;
  border-radius: 3px;
}
.dead:focus {
  outline: 0;
}
