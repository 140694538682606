@font-face {
  font-family: sspb;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
}

.Outer {
  width: 100%;
  height: 100%;
  padding: 0px;
  position: relative;
}
.innerText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 400;
}

.number {
  font-family: sspb;
}

.afterDecimal {
  font-size: 14px;
  padding-top: 7.5px;
  font-family: sspb;
}

.totalDiv {
  font-family: sspb;
}

@media (max-width: 1024px) {
  .innerText {
    font-size: 14px;
  }
  .afterDecimal {
    font-size: 11px;
    padding-top: 7.5px;
  }
}
