@font-face {
  font-family: Lato;
  src: url("../../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: ssp;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.noneDiv {
  display: none;
}
.outerContainer {
  position: relative;
}
.aModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 18vw;
  height: 25vh; */
  width: 337px;
  height: 211px;
  border-radius: 5px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none;
  z-index: 900;
  padding: 0 25px 0 25px;
}

.aModal:focus {
  outline: 0;
}

.dModal {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  /* width: 18vw;
    height: 25vh; */
  border-radius: 5px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none;
  z-index: 900;
  padding: 20px 25px 0 25px;
  width: 307px;
  height: 181px;
  display: flex;
  flex-direction: column;
}
.dModal:focus {
  outline: 0;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 900;
}

.header {
  -webkit-text-stroke: 0.3px #777777;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.86;
  letter-spacing: 0.14px;
  text-align: left;
  color: #777777;
}
.content {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.12px;
  text-align: left;
  color: #9f9f9f;
}

.emailInput {
  border: 0;
  padding: 0 0 0 10px;
  font-family: ssp;
}
.emailInput:focus {
  outline: 0 !important;
}
.emailInput::placeholder {
  opacity: 0.64;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: left;
  color: #818ca5;
}
.inputOuter {
  padding: 15px 0 5px 0;
  border-bottom: 1px solid #818ca5;
}
.btnsDiv {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 5px 0;
}
.delBtnsDiv {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 15px 0;
  flex: 1;
  align-items: flex-end;
}
.cancelBtn {
  border-radius: 5px;
  background-color: #bcbec6;
  color: #ffffff;
  border: 0;
  text-align: center;
  font-family: ssp;
  padding: 5px 10px;
  flex: 1;
  margin-right: 10px;
  height: 35px;
}
.cancelBtn:focus {
  outline: 0;
  background-color: rgb(113, 114, 117);
}

.confirmBtn {
  height: 35px;
  border-radius: 5px;
  background-color: #416ce1;
  color: #ffffff;
  border: 0;
  text-align: center;
  font-family: ssp;
  margin-left: 10px;
  padding: 5px 10px;
  flex: 1;
}
.confirmBtn:focus {
  outline: 0;
  background-color: #2355e0;
}
.removeBtn {
  border-radius: 5px;
  background-color: #d34a4a;
  color: #ffffff;
  border: 0;
  text-align: center;
  font-family: ssp;
  flex: 1;
  margin-left: 20px;
  padding: 5px 10px;
  height: 35px;
}
.removeBtn:focus {
  outline: 0;
  background-color: #991c1c;
}

/***************** ADD BUTTON ******************/
.btnDiv {
  width: 11%;
}

.contentUnder {
  opacity: 0.68;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: #2b2d38;
  width: 125px;
}

.addButton {
  background-color: #fff;
  border: 0px;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.deleteButton {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  border: 0;
  font-size: 30px;
  font-weight: 500;
  background-color: #d34a4a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deleteButton:focus {
  outline: 0;
  transform: scale(1.1);
}

.addButton:focus {
  outline: 0;
}
.img {
  height: 70%;
  width: 70%;
}

@media (max-width: 768px) {
  .aModal {
    top: 50%;
    left: 50%;
  }
  .dModal {
    top: 50%;
    left: 50%;
  }
  .addButton {
    width: 125px;
    height: 125px;
  }

  .deleteButton {
    width: 40px;
    height: 40px;
    font-size: 25px;
  }
  .contentUnder {
    width: 125px;
  }
}

@media (max-width: 576px) {
  .addButton {
    width: 100px;
    height: 100px;
  }

  .deleteButton {
    width: 35px;
    height: 35px;
    font-size: 22px;
  }
  .contentUnder {
    width: 100px;
  }
}
