@font-face {
  font-family: ssp;
  src: url("../../../Assets//Fonts/SourceSansPro-Semibold.otf");
  font-display: swap;
}

.container {
  display: grid;
  height: 100%;
  width: 90%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "one two" "three four";
  background-color: #f8f8f8;
  border-radius: 10px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.circle {
  height: 10px;
  width: 10px;
  background-color: #d3d3d3;
  border-radius: 5px;
}

.content {
  font-family: ssp;
  color: #2b2d38;
  padding-left: 10px;
}

.one {
  grid-area: one;
  display: flex;
  align-items: center;

  padding: 10px 10px 10px 20px;
}
.two {
  grid-area: two;
  display: flex;
  align-items: center;

  padding: 10px 10px 10px 20px;
}
.three {
  grid-area: three;
  display: flex;
  align-items: center;

  padding: 10px 10px 10px 20px;
}
.four {
  grid-area: four;
  display: flex;
  align-items: center;

  padding: 10px 10px 10px 20px;
}

@media (max-width: 576px) {
  .content {
    font-family: ssp;
    color: #2b2d38;
    padding-left: 10px;
    font-size: 10px;
  }
  .one,
  .two,
  .three,
  .four {
    padding: 5px 5px 5px 10px;
  }
}
