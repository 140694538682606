.container {
  width: 95%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}
.headerDiv {
  flex: 1;
}
.rows {
  overflow: hidden;
  overflow-x: hidden;
  flex: 8;
}

@media (max-width: 576px) {
  .container {
    width: 100%;
  }
}
