.nav {
  display: block;
}

.link {
  padding: 10px 10px 15px 15px;
  text-decoration: none;
  letter-spacing: 1px;
  color: black;
}

.link:hover {
  opacity: 0.5;
  color: black;
}

.active {
  color: #416ce1;
  margin: 10px 10px 15px 15px;
  letter-spacing: 1px;
  padding-top: 5px;
  border-top: 2px solid #416ce1;
}

.container {
  background-color: white;
  padding: 15px;
  box-shadow: 2px 2px 7px grey;
}

.logo {
  height: 25px;
  width: 120px;
}

.buttonDiv {
  float: right;
  margin-right: 20px;
  justify-content: center;
}

.saveButton {
  margin-right: 25px;
  width: 120px;
}

.button {
  width: 120px;
}
