@font-face {
  font-family: LatoB;
  src: url("../../Assets/Fonts/Lato-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SFPro;
  src: url("../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.greyEdit {
  background-color: #bcbec6;
  width: 103px;
  height: 29px;
  border-radius: 5px;
  border: 0;
  color: #fff;
}

.greyEdit:focus {
  outline: 0;
}

.blueEdit {
  width: 103px;
  height: 29px;
  border-radius: 5px;
  background-color: #416ce1;
  border: 0;
  color: #fff;
}

.blueEdit:focus {
  outline: 0;
}

.advisorEmail {
  font-size: 13px;
  font-family: SFPro;
}

.advisorCompany {
  font-size: 14px;
  font-family: SFPro;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
}
.upper {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.header {
  flex: 8;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.23px;
  color: #404a57;
  font-family: LatoB;
}
.advisorSection {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  place-items: center;
  gap: 15px;
}
.details {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  position: relative;
  border: solid 2px #416ce1;
  background-color: #fff;
  display: grid;
  place-items: center;
}

.belowText {
  text-align: center;
}

.delDiv {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.btnSection {
  display: flex;
  justify-content: center;
  flex: 2;
}

.add {
  border: 0px;
}

@media (max-width: 1400px) {
  .advisorSection {
    grid-template-columns: repeat(3, 1fr);
    padding: 10px 20px;
  }
  .upper {
    height: 50px;
  }
}
@media (max-width: 768px) {
  .item {
    width: 125px;
    height: 125px;
  }
  .belowText {
    max-width: 125px;
    text-align: center;
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .root {
    flex-direction: column;
  }
  .btnSection {
    display: flex;
    justify-content: center;
  }
  .advisorSection {
    grid-template-columns: repeat(2, 1fr);
    padding: 10px 0px;
  }
  .item {
    width: 100px;
    height: 100px;
  }
  .belowText {
    max-width: 120px;
    text-align: center;
    font-size: 12px;
  }
  .upper {
    height: 50px;
  }
}
