@font-face {
  font-family: Lato;
  src: url("../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90vh;
  width: 23vw;
  background-color: #ffffff;
  border: none;
  overflow-y: auto;
  font-family: Lato;
  z-index: 2;
  max-height: 700px;
  min-width: 290px;
}

.Modal:focus {
  outline: 0;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 2;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  padding: 20px 0px;
}

.header {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.54;
  letter-spacing: normal;
  text-align: center;
  color: #43444c;
  flex: 1;
  padding: 10px 0px;
}

.content {
  width: 85%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.image {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerImage {
  width: 70%;
}

.inputBox {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  flex: 1;
}

.t1 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #4d4f5c;
}

.t2 {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #4d4f5c;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.closeButton:focus {
  outline: none;
}

.btnDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 1630px) {
  .Modal {
    width: 30vw;
  }
}

@media (max-width: 1280px) {
  .Modal {
    width: 40vw;
  }
}

@media (max-width: 1024px) {
  .Modal {
    width: 50vw;
    height: 70vh;
    max-height: 800px;
  }
}

@media (max-width: 768px) {
  .Modal {
    width: 70vw;
    height: 70vh;
    min-height: 500px;
  }
  .crossButton {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 576px) {
  .Modal {
    width: 95vw;
    height: 90vh;
    min-height: 550px;
    overflow-y: scroll;
  }
}
