.range-container {
  position: relative;
}

input[type='range'] {
  width: 350px;
  margin: 18px 0;
  -webkit-appearance: none;
  margin-left: -20px;
}

input[type='range']:focus {
  outline: none;
}

input[type='range'] + label {
  background-color: #fff;
  position: absolute;
  top: -25px;
  left: 110px;
  width: 80px;
  padding: 5px 0;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Chrome & Safari */
input[type='range']::-webkit-slider-runnable-track {
  background: linear-gradient(to right, rgb(243, 76, 76), rgb(50, 250, 50));
  border-radius: 4px;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 34px;
  width: 4px;
  background: black;

  margin-top: -3px;
  cursor: pointer;
}

/* Firefox */
input[type='range']::-moz-range-track {
  background: purple;
  border-radius: 4px;
  width: 100%;
  height: 14px;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid purple;
  margin-top: -7px;
  cursor: pointer;
}

/* IE */
input[type='range']::-ms-track {
  background: purple;
  border-radius: 4px;
  width: 100%;
  height: 14px;
  cursor: pointer;
}

input[type='range']::-ms-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid purple;
  margin-top: -7px;
  cursor: pointer;
}
