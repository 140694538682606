@font-face {
  font-family: sspb;
  src: url("../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: sspb;
  src: url("../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.root {
  height: 100%;
  width: 100%;
}

.totalDiv {
  width: 100%;
  height: 100%;
}
.part1 {
  height: 36%;
  width: 100%;
}
.part2 {
  height: 64%;
  width: 100%;
}

.header {
  display: flex;
  padding: 15px 20px 20px 20px;
}

.right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-family: sspb;
  font-size: 20px;
  color: #be262b;
}

.left {
  flex: 1;
  font-size: 19px;
  display: flex;
}

.value {
  font-size: 22px;
  font-family: sspb;
}

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1500px) {
  .root {
    width: 100%;
    padding: 10px;
  }
  .totalDiv {
    display: flex;
    width: 100%;
  }

  .part1 {
    flex: 1;
    height: 100%;
  }

  .part2 {
    flex: 1;
    height: 100%;
  }
}

@media (max-width: 1280px) {
  .totalDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .part1 {
    /* background-color: darkorange; */
    flex: 1;
  }

  .part2 {
    /* background-color: darkorange; */
    flex: 1;
  }
}

@media (max-width: 1025px) {
  .totalDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
  }

  .part1 {
    flex: 1;
  }

  .part2 {
    flex: 2;
    /* height: 400px; */
  }
}

@media (max-width: 576px) {
  .totalDiv {
    overflow: scroll;
  }
  .part1 {
    height: 50%;
    width: 100%;
    min-height: 320px;
  }
  .part2 {
    height: 50%;
    width: 100%;
    min-height: 480px;
  }
}
