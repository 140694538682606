@font-face {
  font-family: ssp;
  src: url("../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ssp;
  font-size: 18px;
  padding: 5px 0px 5px 10px;
  color: #4d4f5c;
}
