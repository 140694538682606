@font-face {
  src: url("../../Assets/Fonts/Lato-Bold.ttf");
  font-family: Latob;
  font-display: swap;
}

.tableBox {
  padding: 3%;
  width: 97%;
  height: 97%;
  box-shadow: 0 2px 4px 0 rgba(195, 195, 195, 0.3);
  border: solid 0.3px #979797;
  border-radius: 2px;
  background-color: #ffffff;
  margin: 1.5%;
  position: relative;
}
.tableBox::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0;
  width: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
  z-index: 2;
}
.tableBox::after {
  content: "";
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0;
  width: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #979797;
}

.headerAll {
  font-family: Latob;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #393939;
  height: 3%;
  padding-top: 0%;
}
.sliderDiv {
  height: 18%;
  /* background-color: darkmagenta; */
  /* min-height: 100px; */
}
.tableDiv {
  height: 79%;
  width: 100%;
}

.tableBody {
  /* overflow-y: scroll; */
  height: 82%;
  width: 100%;
}
.tableBody::-webkit-scrollbar {
  width: 0px;
}
.tableBody::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
/* .tableBody:nth-child(even) {
  background-color: red;
} */

.tableBody::-ms-scrollbar {
  width: 0px;
}
.tableBody::-ms-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

@media (max-width: 1500px) {
  .tableBox::before {
    border-bottom: 0px solid transparent;
  }
  .tableBox::after {
    border-bottom: 0px solid transparent;
  }
  .tableBox {
    margin-top: -5px;
    height: 100%;
    box-shadow: 0 0px 0px 0 rgba(195, 195, 195, 0.3);
    border: 0;
  }
  .headerAll {
    height: 2%;
  }
  .sliderDiv {
    height: 22%;
  }
  .tableDiv {
    height: 76%;
    width: 100%;
  }

  .tableBody {
    /* overflow-y: scroll; */
    height: 82%;
    width: 100%;
  }
}
@media (max-width: 1280px) {
  .headerAll {
    height: 1%;
  }
  .sliderDiv {
    height: 22%;
  }
  .tableDiv {
    height: 77%;
  }
}
