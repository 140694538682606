@font-face {
  font-family: Lato;
  src: url("../../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 600px;
  width: 23vw;
  background-color: #ffffff;
  border: none;
  z-index: 2;
  padding: 25px 0;
  overflow: auto;
}

.Modal:focus {
  outline: 0;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 2;
}
.header {
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.54;
  letter-spacing: normal;
  text-align: center;
  color: #43444c;
  margin: 20px 0 35px 0;
}
.btnDiv {
  display: flex;
  justify-content: center;
}
.registerBtn {
  border-radius: 5px;
  background-color: #406bdf;
  border: 0;
  color: #fff;
  width: 151px;
  height: 40px;
}
.registerBtn:focus {
  outline: 0;
}
.crossButton {
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
}

@media (max-width: 1630px) {
  .Modal {
    width: 30vw;
  }
}

@media (max-width: 1280px) {
  .Modal {
    width: 40vw;
  }
}

@media (max-width: 1024px) {
  .Modal {
    width: 50vw;
  }
}

@media (max-width: 768px) {
  .Modal {
    width: 70vw;
  }
  .crossButton {
    height: 35px;
    width: 35px;
  }
}

@media (max-width: 576px) {
  .Modal {
    width: 90vw;
  }
}
