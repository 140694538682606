@font-face {
  font-family: Lato;
  src: url('../../Assets//Fonts/Lato-Regular.ttf');
  font-display: swap;
}

/* Button to login from Fees */
.fromFeesBtn {
  background-color: transparent;
  color: #406bdf;
  text-decoration: underline;
  cursor: pointer;
  border: 0;
  outline: 0;
}
.fromFeesBtn:hover {
  color: #406bdf;
  text-decoration: underline;
}

.myButton {
  width: 300px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #416ce1;
  border: none;
  outline: none !important;
  color: white;
  font-size: 15px;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 650px;
  width: 70vw;
  background-color: #ffffff;
  border: none;
  overflow-y: auto;
  font-family: Lato;
  z-index: 2;
}

.initModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 580px;
  width: 400px;
  background-color: #ffffff;
  border: none;
  overflow-y: auto;
  font-family: Lato;
  z-index: 2;
}
.initModal:focus {
  outline: 0;
}

.Modal:focus {
  outline: 0;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 2;
}

.signupBtn {
  width: 119px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #406bdf;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: 1px solid #406bdf;
  transition: all 0.4s ease-out;
}

.signupBtn:hover {
  background-color: #ffffff;
  color: #406bdf;
}

.signupBtn:focus {
  outline: none;
}

.closeContainer {
  position: relative;
}

.closeButton {
  position: absolute;
  right: -15px;
  top: -15px;
  height: 40px;
  width: 40px;
  background-color: white;
  border: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.closeButton:focus {
  outline: none;
}

.timeline {
  width: 80%;
  height: 8vh;
  margin-top: 2vh;
}

.crossButton {
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
}

.container {
  display: grid;
  /* background-color: teal; */
  grid-template-columns: 2fr 3fr;
  height: 100%;
  width: 100%;
  grid-template-rows: 1fr 3fr;
  grid-template-areas: 'image header' 'image main';
  animation: opacEffect 2s ease-out;
}

.header {
  grid-area: header;
  /* background-color: coral; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  animation: opacEffect 2s ease-out;
}

.backButton {
  position: absolute;
  left: 15px;
  top: 2px;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.heading {
  font-family: Lato;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.55;
  letter-spacing: 0.22px;
  text-align: center;
  color: #43444c;
}

.image {
  grid-area: image;
  background-color: #f4f7ff;
  /* padding: 0 2%; */
  animation: opacEffect 2s ease-out;
}

.img {
  height: 100%;
  width: 100%;
}

.main {
  grid-area: main;
  /* background-color: rebeccapurple; */
  animation: opacEffect 2s ease-out;
}

@keyframes opacEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1280px) {
  .Modal {
    width: 85vw;
    /* max-width: 50; */
  }
}

@media (max-width: 1024px) {
  .container {
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 20% 30% 50%;
    grid-template-areas: 'header' 'image' 'main';
  }

  .Modal {
    height: 80vh;
    width: 65vw;
  }
  .image {
    padding: 2% 0;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 20% 30% 50%;
    grid-template-areas: 'header' 'image' 'main';
  }

  .Modal {
    height: 85vh;
    width: 75vw;
  }
  .initModal {
    width: 95%;
  }
  .image {
    padding: 2% 0;
  }
  .crossButton {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 20% 30% 50%;
    grid-template-areas: 'header' 'image' 'main';
  }

  .Modal {
    height: 90vh;
    max-height: 730px;
    width: 90vw;
    overflow: scroll;
  }
  .image {
    padding: 2% 0;
  }
  .initModal {
    height: 80vh;
    max-height: 650px;
    overflow: scroll;
  }
  .backButton {
    position: absolute;
    left: 15px;
    top: 2px;
    height: 35px;
    width: 35px;
    cursor: pointer;
  }
}
