@font-face {
  font-family: ssp;
  src: url("../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 90%;
}
.textContent {
  font-size: 15px;
  font-family: ssp;
}
.root {
  height: 5vh;
  width: 120px;
  display: flex;
}

.inputBox {
  height: 30px;
  width: auto;
  max-width: 120px;
  padding-left: 1.5vw;
  border: none;
  border-bottom: 2.5px solid #416ce1;
  color: #416ce1;
  font-family: ssp;
  outline: none !important;
}

.inputIcon {
  border: none;
  border-bottom: 2.5px solid #416ce1;
  height: 30px;
  width: 1.5vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #416ce1;
  font-family: ssp;
}

@media (max-width: 768px) {
  .root {
    width: 110px;
  }
  .inputIcon {
    width: 4vw;
  }
  .inputBox {
    max-width: 110px;
    padding-left: 4vw;
  }
}
