.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.logo {
  width: 60px;
  height: 80px;
}
.logospan {
  margin-top: 25px;
  margin-bottom: 40px;
}

@media (min-width: 601px) {
  .container {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .container {
    width: 90%;
  }
}

.header {
  font-size: 30px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.sub-header {
  font-size: 20px;
  font-weight: 550;
  padding: 0;
  margin: 0;
  color: #706c6c;
  margin-bottom: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.content > p,
.radio {
  color: #706c6c;
  padding: 5px;
  font-size: 17px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.textarea {
  margin: 5px;
  border-radius: 10px;
}

.buttons-span {
  float: right;
  margin: 10px;
}

.done-button {
  width: 100px;
  border-radius: 5px;
  background-color: #3677d9;
}

.skip-button {
  color: #3677d9;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 18px;
}
