.main {
  margin-top: 20px;
  height: 100px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footerLogo {
  height: 16px;
  margin: 0 4px 0 6px;
}

.cp {
  margin: 0;
}

.content {
  margin: 0;
}

@media (max-width: 768px) {
  .main {
    height: 70px;
  }
  .footerLogo {
    height: 13px;
    width: 50px;
    margin: 0 3px 0 4px;
  }
  .cp {
    margin: 0;
    font-size: 11px;
    font-weight: 500;
  }
  .content {
    font-size: 11px;
  }
}

@media (max-width: 330px) {
  .footerLogo {
    width: 45px;
    margin: 0 2px 0 3px;
  }
  .cp {
    font-size: 10px;
  }
  .content {
    font-size: 10px;
  }
}
