@font-face {
  font-family: Lato;
  src: url('../../Assets//Fonts/Lato-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: sspP;
  src: url('../../Assets/Fonts/SourceSansPro-Regular.otf');
  font-display: swap;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.emailContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

.forgotContainer {
  display: flex;
  justify-content: center;
}

.forgotWrapper {
  width: 90%;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
}

.forgot {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.forgotPassword {
  text-decoration: none;
  color: #43425d;
  font-family: sspP;
}

.forgotPassword:hover {
  text-decoration: none;
}

.remember {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #5f5e75;
  font-family: sspP;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px 0 0;
}

.loader {
  height: 5vh;
}

.loginButton {
  width: 180px;
  height: 45px;
  border-radius: 10px;
  background-color: #406bdf;
  color: white;
  border: none;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
}

.loginButton:focus {
  outline: none;
}

.newUser {
  display: flex;
  justify-content: center;
  font-family: Lato;
  font-size: 15px;
  padding: 10px 0 0 0;
}

.signupLink {
  text-decoration: none;
  color: #406bdf;
}

.signupLink:hover {
  text-decoration: none;
  cursor: pointer;
}

.connectWith {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.connectText {
  font-family: sspP;
  font-size: 15px;
  color: #72768c;
  position: relative;
  width: 90%;
  text-align: center;
  background-color: white;
  z-index: 1;
}

.connectText::before {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.08);
  margin: 0 auto;
  width: 100%;
  height: 2px;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.oAuth {
  display: flex;
  padding-bottom: 20px;
  width: 100%;
  align-self: center;
  justify-content: center;
}
.oAuth1 {
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;

  margin-top: 10px;
}

.google {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
}

.fb {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 20px;
}

.disabled {
  background-color: grey;
  cursor: not-allowed;
}

@media (max-width: 576px) {
  .remember {
    font-size: 14px;
  }
}
