@font-face {
  font-family: LatoB;
  src: url("../../Assets/Fonts/Lato-Bold.ttf");
  font-display: swap;
}
.root {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 10px 30px;
  gap: 25px;
  place-items: center;
}
.header {
  height: 70px;
  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.23px;
  color: #404a57;
  font-family: LatoB;
}
.item {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: solid 2px #416ce1;
  background-color: #fff;
  display: grid;
  place-items: center;
}
.item:hover {
  transform: scale(1.05);
}
.img {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.details {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.belowText {
  text-align: center;
  padding: 10px 0;
}

@media (max-width: 1400px) {
  .root {
    grid-template-columns: repeat(4, 1fr);
    padding: 10px 20px;
  }
}
@media (max-width: 1024px) {
  .root {
    grid-template-columns: repeat(3, 1fr);
    padding: 10px 20px;
  }
}
@media (max-width: 768px) {
  .item {
    width: 125px;
    height: 125px;
  }
}
@media (max-width: 576px) {
  .root {
    grid-template-columns: repeat(2, 1fr);
  }
  .item {
    width: 100px;
    height: 100px;
  }
}
