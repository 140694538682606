@font-face {
  font-family: sspb;
  src: url("../../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../../../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Lato-Bold;
  src: url("../../../../Assets/Fonts/Lato-Bold.ttf");
  font-display: swap;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  flex-direction: column;
}

.loading {
  font-family: Lato;
  font-size: 20px;
}

.graphCard {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.04);
  width: 90%;
  height: 90%;
  align-items: center;
}

.netCard {
  width: 85%;
  height: 100%;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 9vh 7vh 5vh 54vh; */
  grid-template-rows: 80px 64px 40px 400px;
  grid-template-areas: "pickers pickers" "search search" "data data" "accord graph";
}

.search {
  grid-area: search;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchDiv {
  width: 95%;
  padding: 10px 0px;
}

.empty {
  grid-area: empty;
}

.netContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: 15vh 50vh auto; */
  grid-template-rows: 95px 350px auto;
  grid-template-areas: "netPickers" "netGraph" "netAccord";
  height: auto;
  overflow: auto;
  padding-top: 20px;
}

.other {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 10vh 65vh; */
  grid-template-rows: 65px 510px;
  grid-template-areas: "pickers pickers" "accord graph";
}

.accordArea {
  grid-area: accord;
  padding: 0px 40px;
  height: 95%;
}

.netAccordArea {
  grid-area: netAccord;
  display: flex;
  justify-content: center;
  height: auto;
}

.graphArea {
  grid-area: graph;
  margin-top: 20px;
}

.netGraphArea {
  grid-area: netGraph;
}

.accordCont {
  overflow: auto;
  height: 95%;
  margin-top: 20px;
}

.dataPickerWrapper {
  width: 95%;
  display: flex;
  justify-content: flex-end;
}

.pickers {
  grid-area: pickers;
  display: grid;
  grid-template-columns: 10fr 3fr;
  /* grid-template-rows: 8vh; */
  grid-template-rows: 65px;
  grid-template-areas: "textArea date";
  width: 100%;
  padding-left: 40px;
}

.netPickers {
  grid-area: netPickers;
  display: grid;
  grid-template-columns: 10fr 3fr;
  /* grid-template-rows: 10vh; */
  grid-template-rows: 80px;
  grid-template-areas: "textArea date";
  width: 85%;
  margin: 0 auto;
}

.expandedContainer {
  width: 100%;
}

.text {
  grid-area: textArea;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.afterDecimal {
  font-size: 20px;
  padding-top: 4px;
}

.actualText {
  height: 4vh;
  font-size: 18px;
  display: flex;
  align-items: flex-start;
}

.value {
  height: 5vh;
  font-size: 30px;
  font-family: sspb;
}

.dataPicker {
  grid-area: data;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datePicker {
  grid-area: date;
}

.pieChart {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.pieCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92%;
  width: 90%;
}

.netAccordCont {
  width: 100%;
}

@media (max-width: 1440px) {
  .accordArea {
    grid-area: accord;
    padding: 10px 20px;
    overflow: auto;
  }

  .pickers {
    grid-area: pickers;
    display: grid;
    grid-template-columns: 3fr 1fr;
    /* grid-template-rows: 10vh; */
    grid-template-rows: 80px;
    grid-template-areas: "textArea date";
    width: 100%;
    padding-left: 40px;
  }

  .netPickers {
    grid-area: netPickers;
    display: grid;
    grid-template-columns: 4fr 1fr;
    /* grid-template-rows: 10vh; */
    grid-template-rows: 80px;
    grid-template-areas: "textArea date";
    width: 85%;
    margin: 0 auto;
  }

  .actualText {
    height: 4vh;
    font-size: 15px;
    display: flex;
    align-items: flex-end;
  }

  .value {
    height: 6vh;
    font-size: 27px;
  }
}

@media (max-width: 1024px) {
  .container {
    grid-template-columns: 1fr;
    /* grid-template-rows: 10vh 40vh 5vh 6vh auto; */
    grid-template-rows: 100px 500px 70px 70px auto;
    grid-template-areas: "pickers" "graph" "search" "data" "accord";
  }

  .other {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-rows: 10vh 40vh auto; */
    grid-template-rows: 100px 550px auto;
    grid-template-areas: "pickers" " graph" "accord";
  }

  .pickers {
    width: 100%;
  }

  .accordCont {
    overflow: hidden;
    height: auto;
  }

  .searchDiv {
    width: 90%;
  }

  .pickers {
    grid-area: pickers;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 100px;
    grid-template-areas: "textArea date";
    width: 100%;
    padding-left: 40px;
  }

  .netContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100px 450px auto;
    grid-template-areas: "netPickers" "netGraph" "netAccord";
    height: auto;
  }

  .dataPickerWrapper {
    width: 90%;
  }

  .accordArea {
    grid-area: accord;
    padding: 0px 30px 20px 30px;
    overflow: visible;
    height: auto;
  }
  .actualText {
    height: 4vh;
    font-size: 15px;
    display: flex;
    align-items: flex-end;
  }

  .value {
    height: 6vh;
    font-size: 28px;
  }
  .pieCard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 90%;
  }
  .netAccordArea {
    grid-area: netAccord;
    padding: 40px 40px;
    height: auto;
  }
}

@media (max-width: 576px) {
  .accordArea {
    padding: 0;
    margin: 0;
  }
  .container {
    grid-template-columns: 1fr;
    /* grid-template-rows: 10vh 40vh 5vh 10vh auto; */
    grid-template-rows: 70px 320px 40px 60px auto;
    grid-template-areas: "pickers" "graph" "search" "data" "accord";
  }

  .other {
    grid-template-rows: 70px 320px auto;
  }

  .actualText {
    height: 4vh;
    font-size: 13px;
    display: flex;
    align-items: flex-end;
  }

  .netAccordArea {
    grid-area: netAccord;
    padding: 40px 0px;
    height: auto;
  }

  .netCard {
    width: 100%;
    height: 90%;
  }

  .value {
    height: 6vh;
    font-size: 20px;
  }

  .afterDecimal {
    font-size: 14px;
  }

  .pickers {
    grid-area: pickers;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 70px;
    grid-template-areas: "textArea date";
    width: 100%;
    padding-left: 20px;
  }
  .netContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 300px auto;
    grid-template-areas: "netPickers" "netGraph" "netAccord";
    height: auto;
    overflow: scroll;
  }
  .graphArea {
    margin-top: 0px;
  }
}
