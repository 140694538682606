@font-face {
  font-family: ssp;
  src: url("../../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

.signup {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px 0px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.signupButton {
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #34d86a;
  font-family: Lato;
  font-size: 17px;
  transition: background-color 300ms;
  padding: 5px 0px;
  width: 150px;
}

.signupButton:hover {
  background-color: #ecfff3;
  border: 1px solid #34d86a;
  color: #34d86a;
  transition: background-color 300ms;
}

.signupButton:focus {
  outline: none;
}

@media (max-width: 576px) {
}
