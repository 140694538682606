.wrapper {
  position: relative;
}
.footerContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  height: 500px;
  width: 100%;
}
.footerTop {
  background-color: #406bdf;
  flex: 7;
  min-height: 50%;
}
.footerBottom {
  background-color: #ffffff;
  flex: 7;
  display: flex;
  justify-content: center;
  padding: 15px 0 0 0;
}

@media (max-width: 768px) {
  .footerContainer {
    height: auto;
  }
  .footerTop {
    flex: 8;
    padding: 5px;
  }
  .footerBottom {
    flex: 6;
  }
}
