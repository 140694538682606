.gapper {
  margin-top: 10vh;
}
.main {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img {
  width: 40vw;
}
.philoH1 {
  font-size: 40px;
}
.philoP {
  font-size: 18px;
  width: 35vw;
}
.philoH2 {
  font-size: 34px;
  margin-bottom: 16px !important;
  margin-top: 5px;
}

.philoH3 {
  font-size: 27px;
  margin-bottom: 16px !important;
  margin-top: 5px;
}

.textContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 7vw;
  flex: 1;
}

.mainphilosophy {
  margin-top: 5vh;
  margin-left: 18vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.ulTag ul {
  list-style-type: none;
  padding: 0px;
}
.ulItem {
  position: relative;
}
.square {
  width: 9px;
  height: 9px;
  background-color: #3d86ef;
  top: 7px;
  position: absolute;
}
.squareP {
  width: 2%;
  display: inline-block;
}
.ptagP {
  width: 75%;
  display: inline-block;
}
.ulH4 {
  display: inline-block;
}

.ulTag ul h4 {
  color: #416ce1;
}
.ulTag ul p {
  font-size: 16px;
}
.carouselIndicators li {
  background-color: #416ce1;
}
@media (max-width: 575px) {
  .mainphilosophy {
    margin-left: 10px;
    align-content: center;
  }

  .ulTag ul {
    list-style-type: none;
    padding-left: 50px;
  }

  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    height: 77vh;
  }
  .img {
    margin: 0 auto;
    width: 100%;
    display: block;
    margin-bottom: 22px;
    margin-top: 18px;
    float: none;
  }
  ul h4 {
    font-size: 19px;
  }
  .ulTag ul p {
    font-size: 14px;
  }
  .ulTag ul h4 {
    font-size: 19px;
  }
  .ptagP {
    width: 90%;
    margin-left: 10px;
  }
  .philoH1 {
    font-size: 32px;
    /* text-align: center; */
  }

  .squareP {
    margin-left: -7vw;
  }

  .philoH2 {
    font-size: 27px;
    margin-bottom: 16px !important;
  }

  .philoP {
    font-size: 17px;
    text-align: center;
    width: 95%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .mainphilosophy {
    align-content: center;
  }
  .img {
    margin: 0 auto;
    width: 500px;
    display: block;
    margin-bottom: 22px;
    margin-top: 18px;
    float: none;
  }
  .philoH1 {
    font-size: 35px;
    text-align: center;
  }
  .philoH2 {
    font-size: 30px;
    margin-bottom: 16px !important;
  }
  .ulTag ul h4 {
    font-size: 21px;
  }
  .philoP {
    font-size: 17px;
    text-align: center;
  }
  .button {
    width: 165px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1122px) {
  .img {
    width: 585px;
  }
}
