@font-face {
  font-family: sspb;
  src: url("../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 10px;
}

.text {
  font-size: 18px;
  font-family: sspb;
}
