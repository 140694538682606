.tab {
  background-color: #ffffff;
  border: 0.1px solid #416ce1;
  color: #000000;
  width: 11vw;
  min-height: 35px;
  font-size: 14px;
  min-width: 120px;
}

.left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}

.right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
  width: 12vw;
}

.title {
  font-size: 20px;
  margin-bottom: 20px;
}

.activeTab {
  font-weight: 500;
  background-color: #416ce1;
  color: #ffffff;
  border: 1px solid #416ce1;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 20px 0px;
}

.item > p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
}

.item > p > span {
  font-size: 12px;
  font-weight: 400;
}

.tab:hover {
  opacity: 0.6;
}

.activeTab:hover {
  opacity: 1;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal {
  border-radius: 20px;
  border: 0.1px solid #416ce1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 37vw;
  min-width: 400px;
  background-color: #ffffff;
  z-index: 2;
  align-items: center;
  justify-content: center;
}

.input {
  border: 1px solid #416ce1;
  width: 7vw;
  height: 35px;
  display: flex;
  text-align: center;
}

.inputDiv {
  margin-right: 30px;
  align-items: center;
}
.childDiv {
  display: flex;
}
.footer {
  display: flex;
  /* padding: 5px;
  padding-right: 10px;
  padding-bottom: 0px; */
  padding: 5px 10px 0px 5px;
  border-top: 0.1px solid #416ce1;
  align-items: center;
}

.footer > p {
  font-size: 11px;
  font-weight: 500;
  margin-top: 10px;
}

.icon {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  margin-top: -5px;
}

.toolTip {
  text-align: left;
  font-size: 12px;
  color: grey;
  margin-right: 5px;
  margin-top: 3px;
  margin-bottom: 0px;
}

.filing {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px;
}

.child {
  margin-right: 0px;
}

@media screen and (max-width: 580px) {
  .modal {
    min-width: 350px;
  }

  .item > p {
    font-size: 14px;
  }

  .input {
    min-width: 100px;
    margin-right: 0px;
    height: 30px;
  }

  .footer > p {
    font-size: 9px;
    font-weight: 500;
  }

  .icon {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .toolTip {
    text-align: left;
    font-size: 10px;
  }

  .title {
    font-size: 16px;
  }
  .inputDiv {
    margin-right: 20px;
    align-items: center;
    margin-top: -10px;
  }

  .childDiv {
    display: flex;
    margin-right: -10px;
    margin-top: -10px;
  }
}
