@font-face {
  font-family: Lato;
  src: url("../../Assets//Fonts/Lato-Regular.ttf");
  font-display: swap;
}

/* Email already Exists... */
.alreadyBtn {
  color: #1bad53;
  font-size: 11.5px;
  border: 0;
  outline: 0;
  text-decoration: underline;
  background-color: transparent;
}
.alreadyBtn:hover {
  text-decoration: underline;
}

.inputContainer {
  border-radius: 5px;
  box-shadow: 0 4px 16px 0 rgba(69, 91, 99, 0.08);
  border: solid 0.5px #406bdf;
  width: 100%;
  padding: 4px;
  padding-left: 8px;
  font-size: 16px;
  height: 33px;
  font-family: Lato;
  max-height: 40px;
}
.container {
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  border: none;
  margin-bottom: 8px;
  padding-bottom: 16px;
  font-family: Lato;
}
.title {
  font-size: 15px;
  color: #2b2d38;
  margin-bottom: 3px;
  font-family: Lato;
}

@media (max-width: 768px) {
  .inputContainer {
    border-radius: 4px;
    padding: 2px;
    padding-left: 6px;
    font-size: 14px;
    height: 28px;
  }
  .container {
    margin-bottom: 4px;
    padding-bottom: 12px;
  }
  .title {
    font-size: 12px;
    margin-bottom: 2px;
  }
}
