.modal {
  border-radius: 10px;
  border: 0.1px solid #416ce1;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 31vw;
  min-width: 400px;
  background-color: #ffffff;
  z-index: 2;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.header {
  font-size: 20px;
  font-weight: 500;
}

.button {
  align-self: center;
  background-color: #416ce1;
  padding: 5px 10px 5px 10px;
  min-width: 100px;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0px grey;
}
