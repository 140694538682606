@font-face {
  font-family: ssp;
  src: url("../../Assets//Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: sspbold;
  src: url("../../Assets/Fonts/SourceSansPro-Bold.otf");
  font-display: swap;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: none;
  z-index: 999;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-width: 300px;
  max-width: 350px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.219);
}

.Modal:focus {
  outline: 0;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 999;
}

@supports not (backdrop-filter: none) {
  .Overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}

.header {
  font-size: 20px;
  font-family: sspbold;
}

.textContent {
  padding: 20px;
  font-family: ssp;
}

.btnDiv {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 10px;
}

.left_btn {
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #bcbec6;
  color: #ffffff;
  border: 0;
  text-align: center;
  font-size: 14px;
  flex: 1;
  font-family: ssp;
  max-width: 150px;
}

.right_btn {
  border-radius: 5px;
  background-color: #416ce1;
  color: #ffffff;
  border: 0;
  text-align: center;
  font-size: 14px;
  padding: 5px 20px;
  flex: 1;
  margin-left: 25px;
  font-family: ssp;
  max-width: 150px;
}

@media (max-width: 576px) {
  .Modal {
    padding: 5px;
    width: 330px;
  }
}
