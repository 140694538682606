.main {
  height: 100%;
  width: 100%;
}
.card {
  height: 38%;
  width: 95%;
  border: 1px solid #3a67e4;
  border-radius: 15px;
}
.imgClass {
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
.car {
  overflow: hidden;
  height: 100%;
}
.btns {
  display: none;
}
