@font-face {
  font-family: ssp;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.dateCol {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  font-size: 15px;
  background-color: white;
  font-family: ssp;
  margin-bottom: 3vh;
  min-height: 40px;
}

.wrapper {
  background-color: white;
}

.contentWrapper {
  width: 90%;
  background-color: white;
  margin-bottom: 3vh;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.bothWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topLeft {
  display: flex;
  height: auto;
  min-height: 4vh;
  color: #416ce1;
  font-size: 20px;
  align-items: center;
  margin-left: 3vw;
  white-space: -moz-pre-wrap; /* Mozilla */
  white-space: -hp-pre-wrap; /* HP printers */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
  word-break: break-all;
  font-family: ssp;
}

.leftBottom {
  display: flex;
  height: auto;
  min-height: 30px;
  color: #353535;
  font-size: 13px;
  margin-left: 3vw;
  align-items: flex-start;
  padding: 5px 0px 10px 0px;
}

.money {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: ssp;
}

.decimal {
  font-size: 15px;
  padding-top: 1px;
}

@media (max-width: 1440px) {
  .topLeft {
    font-size: 17px;
    margin-left: 3vw;
  }
}

@media (max-width: 1024px) {
  .topLeft {
    font-size: 15px;
    padding: 10px 0px;
  }
  .money {
    font-size: 19px;
  }

  .decimal {
    padding-top: 3px;
  }
}

@media (max-width: 576px) {
  .money {
    font-size: 17px;
  }

  .decimal {
    font-size: 13px;
    padding-top: 3px;
  }
  .leftBottom {
    font-size: 12px;
  }
}
