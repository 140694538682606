:focus {
  outline: none !important;
}

.topWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.linkDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
}
.link1 {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.link2 {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.link {
  color: #f1f1f1;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 300;
}
.link:hover {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}
.BtnsDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.header {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.appStore {
  width: 170px;
  height: 40px;
  background-color: #f7f7f7;
  border-radius: 20px;
  color: black;
  border: none;
  outline: none;
}

.link {
  text-decoration: none;
  color: white;
}

.link:hover {
  text-decoration: none;
  color: white;
}

.splink {
  text-decoration: none;
  color: black;
}

.splink:hover {
  text-decoration: none;
  color: black;
}
.googlePLay {
  width: 170px;
  height: 40px;
  background-color: black;
  border-radius: 20px;
  color: white;
  border: none;
  outline: none;
}
.subscribeDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.heading {
  color: #fbfdff;
  font-weight: 400;
  font-size: 18px;
}
.tagLine {
  color: #fbfdff;
  font-weight: 400;
  font-size: 16px;
}
.socialIcons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.icon {
  margin-right: 5px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #406bdf;
  transition: background-color 400ms;
  text-decoration: none;
}

.icon:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: #406bdf;
  color: white;
  border: 2px solid white;
  transition: background-color 200ms;
}

.ipt {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 5px;
  color: #fff;
}
.ipt:focus {
  outline: none;
}
.ipt::placeholder {
  color: #fff;
  font-size: 14px;
  text-align: center;
}
.ipt::-webkit-input-placeholder {
  color: #fff;
  font-size: 14px;
  text-align: center;
}
.submitBtn {
  background-color: #fff;
  color: #406bdf;
  outline: 0;
  border-radius: 0 3px 3px 0;
  border: 1px solid #fff;
  padding: 5px 10px;
  transition: all 0.3s ease-out;
}
.submitBtn:focus,
.submitBtn:hover {
  outline: 0;
  background-color: rgb(238, 235, 235);
  color: #4c6fcf;
}
@media (max-width: 1024px) {
  .link1 {
    width: 75%;
  }
  .link2 {
    width: 90%;
  }
  .ipt {
    padding: 4px;
  }
  .submitBtn {
    padding: 4px 9px;
  }
}

@media (max-width: 810px) {
  .ipt {
    padding: 3px;
  }
  .submitBtn {
    padding: 3px 6px;
  }
}

@media (max-width: 768px) {
  .topWrapper {
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }
  .linkDiv {
    justify-content: center;
    font-size: 16px;
    padding: 10px 0 25px 0;
  }
  .link1 {
    width: 55%;
  }
  .link2 {
    padding: 5px;
    width: 70%;
  }
  .header {
    margin: 5px 0;
  }
  .subscribeDiv {
    margin-top: 10px;
    align-items: center;
  }
  .heading {
    font-size: 16px;
    padding: 2px;
  }
  .tagLine {
    font-size: 14px;
    padding: 4px;
  }
  .socialIcons {
    padding: 10px 0px 2px 0px;
  }
  .appStore {
    margin: 15px 0;
  }
  .shortForm {
    margin: 10px 0;
  }
  .ipt {
    padding: 7px;
  }
  .submitBtn {
    padding: 7px 11px;
  }
}

@media (max-width: 576px) {
  .linkDiv {
    justify-content: center;
    font-size: 16px;
    padding: 5px;
  }
  .link1 {
    width: 75%;
  }
  .link2 {
    padding: 5px;
    width: 90%;
  }
}
