@font-face {
  font-family: Lato;
  src: url("../../Assets//Fonts//Lato-Regular.ttf");
  font-display: swap;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  min-width: 300px;

  background-color: #ffffff;
  border: none;
  z-index: 999;
  padding: 0 0 20px 0;
}

.Modal:focus {
  outline: 0;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 999;
}

.header {
  padding: 50px 0;
}

.h1 {
  font-family: Lato;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.54;
  letter-spacing: normal;
  text-align: center;
  color: #43444c;
}

.h2 {
  padding: 20px 0 5px 0;
  opacity: 0.5;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  text-align: center;
  color: #4d4f5c;
}

.root {
  width: 100%;
  height: 370px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px 30px;
  gap: 25px;

  overflow-y: scroll;
}

.details {
  display: flex;
  flex-direction: column;
}

.belowText {
  text-align: center;
  padding: 10px 0;
}

.item {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 2px #416ce1;
  background-color: #fff;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.img {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.item:hover {
  transform: scale(1.05);
}
