@font-face {
  src: url('../../Assets/Fonts/SourceSansPro-Bold.otf');
  font-family: SFProTextBold;
  font-display: swap;
}
@font-face {
  src: url('../../Assets/Fonts/SourceSansPro-Regular.otf');
  font-family: Sff;
  font-display: swap;
}

.container {
  display: flex;
  background-color: #2b2d38;
  width: 100%;
}

.col {
  border: solid 1px #c6cbde;
  background-color: #f8f8f8;
  padding: 4px 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.col1 {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.col2 {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 10px;
}
.col3 {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.col4 {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 10px;
}
.title {
  font-family: SFProTextBold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.12px;
  text-align: left;
  color: #2b2d38;
  flex: 1;
}
.belowTitle {
  font-family: Sff;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.12px;
  text-align: left;
  color: #2b2d38;
  flex: 1;
}
.belowTitle1 {
  font-family: Sff;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.12px;
  text-align: left;
  margin-top: 25px;
  color: #2b2d38;
  flex: 1;
}
.amtDiv {
  font-family: sff;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.11px;
  text-align: left;
  color: #f54c4c;
  flex: 1;
}
