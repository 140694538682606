.tablist {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.tablistItem {
  margin-top: 5px;
  background-color: #dad8d88a;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4px;
  transition: 0.3s ease-in;
  cursor: pointer;
  align-self: center;
  justify-self: center;
  border-radius: 10px;
}

.selectedItem {
  background-color: #416ce1;
  color: #ffffff;
  height: 5px;
}

.tabdiv {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dummy {
  display: flex;
  width: 100%;
}
.singleItem {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #6e6d6d;
  cursor: pointer;
  margin: 1px;
}
.singleItem:hover {
  color: #416ce1;
}

.selectedSingleItem {
  color: #416ce1;
  font-weight: 400;
}

@media (max-width: 576px) {
  .tabTitle {
    font-size: 12px;
  }
  .tablistItem {
    height: 2px;
  }
  .selectedItem {
    height: 3px;
  }
}
