@import '~antd/dist/antd.css';

@font-face {
	font-family: Lato;
	src: url('./Assets/Fonts/Lato-Regular.ttf');
	font-display: swap;
}

.App {
	font-family: Lato;
}
