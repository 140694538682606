:focus {
  text-decoration: none;
  outline: none !important;
}

:hover {
  text-decoration: none !important;
  outline: none !important;
}

.dropDiv {
  flex: 3;
  display: flex;
  align-items: center;
  position: relative;
}

.name {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.icon {
  padding: 0px 10px 0 0;
  height: 25px;
  cursor: pointer;
}

.signout:hover {
  color: #d34a4a;
}

.text {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #393939;
  text-decoration: none !important;
  outline: none !important;
}
.text:hover {
  text-decoration: none !important;
  outline: none !important;
}

.signOuttext {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  text-decoration: none !important;
  outline: none !important;
}

.listItem {
  display: flex;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #393939;

  text-decoration: none !important;
  outline: none !important;
}

.dropDown {
  position: absolute;
  background-color: white;
  width: 220px;
  z-index: 2;
  top: 45px;
  right: 30px;
  border-radius: 10px;
  border: solid 0.5px #416ce1;
}
