.accordion-section {
  display: flex;
  flex-direction: column;
}

.accordion-section-down {
  display: flex;
  flex-direction: column;
  animation: down 300ms;
}

@keyframes down {
  0% {
    height: 0px;
  }
  100% {
    height: auto;
  }
}

@keyframes up {
  0% {
    height: auto;
  }
  100% {
    height: 0px;
  }
}

.accordion-title {
  background-color: rgb(255, 255, 255);
  color: #444;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.4s ease;
  min-height: 8vh;
  padding: 10px 30px;
  box-sizing: border-box;
  height: auto;
}

.accordion-icon {
  margin-left: auto;
  transition: transform 0.6s ease;
  font-size: 35px;
  color: #416ce1;
}

.rotate {
  transform: rotate(90deg);
}

.titleStyle {
  font-size: "18px";
  padding-right: "10px";
}

.accordion-content {
  background-color: #416ce1cc;
  color: white;
  overflow: hidden;
}

.accordion-content-para {
  padding: 15px;
  font-size: 16px;
}

.plus-minus-toggle {
  cursor: pointer;
  position: relative;
  margin-left: auto;
  background-color: salmon;
  padding-right: 10px;
}
.plus-minus-toggle:before,
.plus-minus-toggle:after {
  background: #416ce1;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 21px;
  transition: transform 500ms ease;
}
.plus-minus-toggle:after {
  transform-origin: center;
}
.plus-minus-toggle.collapsed:after {
  transform: rotate(90deg);
}
.plus-minus-toggle.collapsed:before {
  transform: rotate(180deg);
}

@media (max-width: 576px) {
  .accordion-content {
    transition: max-height 0.3s ease;
    font-size: 14px;
  }
  .titleStyle {
    font-size: 14px;
  }
  .accordion-title {
    padding: 10px 30px;
  }
  .accordion-content-para {
    padding: 15px;
    font-size: 14px;
  }
}
