.gapper {
  margin-top: 10vh;
}
.main {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
}
.img {
  width: 45vw;
}

.philoH1 {
  font-size: 40px;
}
.philoP {
  font-size: 18px;
}
.philoH2 {
  font-size: 34px;
  margin-bottom: 16px !important;
  margin-top: 5px;
}

.faqSection {
  display: flex;
  flex-direction: column;
  padding: 2em;
  background-color: #f3f3f3;
  height: auto;
}

.textContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.faqLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.faqRight {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5vh;
}

.faqDesc {
  font-size: 20px;
}

.faqLeftText {
  width: 30vw;
}

.AccordionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feedback {
  padding: 30px;
}

.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

@media (max-width: 575px) {
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    height: 40vh;
  }
  .img {
    margin: 0 auto;
    width: 100%;
    display: block;
    margin-bottom: 22px;
    margin-top: 18px;
    float: none;
  }
  .ptagP {
    width: 92%;
    margin-left: 9px;
  }
  .philoH1 {
    font-size: 27px;
    text-align: center;
  }
  .philoH2 {
    font-size: 20px;
    margin-bottom: 16px !important;
  }
  .philoP {
    font-size: 17px;
    text-align: center;
    width: 95%;
  }
  .faqSection {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #f3f3f3;
    height: auto;
  }
  .faqDesc {
    font-size: 18px;
  }
  .faqHeader {
    font-size: 25px;
  }
  .faqLeftText {
    width: 100%;
    text-align: center;
  }
  .AccordionContainer {
    width: 100vw;
  }
  .faqRight {
    justify-content: center;
  }
  .feedback {
    padding: 30px 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .img {
    margin: 0 auto;
    width: 500px;
    display: block;
    margin-bottom: 22px;
    margin-top: 18px;
    float: none;
  }
  .philoH1 {
    font-size: 35px;
    text-align: center;
  }
  .philoH2 {
    font-size: 30px;
    margin-bottom: 16px !important;
  }
  .philoP {
    font-size: 17px;
    text-align: center;
  }
  .faqSection {
    display: flex;
    flex-direction: column;
    padding: 2em;
    background-color: #f3f3f3;
    height: auto;
  }
  .faqLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .faqDesc {
    font-size: 18px;
  }
  .faqHeader {
    font-size: 30px;
  }
  .faqLeftText {
    width: 100%;
    text-align: center;
  }
  .AccordionContainer {
    width: 100vw;
  }
  .faqRight {
    justify-content: center;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1122px) {
  .img {
    width: 585px;
  }
  .AccordionContainer {
    width: 100vw;
  }
  .faqRight {
    padding-left: 20px;
  }
  .faqLeftText {
    width: 100%;
    text-align: center;
  }
  .faqHeader {
    font-size: 25px;
  }
  .faqDesc {
    font-size: 15px;
  }
}
