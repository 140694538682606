@font-face {
  font-family: Lato;
  src: url("../../../Assets/Fonts/Lato-Regular.ttf");
  font-display: swap;
}

:focus {
  outline: none !important;
  text-decoration: none !important;
}

.root {
  font-family: Lato;
  width: 100%;
  height: auto;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: aqua; */
}

.jsc {
  border: 1px solid purple;
}

.employer {
  width: 90%;
  /* border: 1px solid purple; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: #406bdf; */
  padding: 30px 0px;
}

.employerText {
  width: 120px;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(69, 91, 99, 0.08);
  border: solid 0.5px #406bdf;
  background-color: #ffffff;
  text-decoration: none !important;
  outline: none;
  padding: 5px;

  font-family: Lato;
}

.customSelect {
  /* background-color: aqua; */
  position: relative;
}

.selectBox {
  width: 100%;
  height: 37px;
  border: solid 1px #406bdf;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 16px 0 rgba(69, 91, 99, 0.08);
  text-decoration: none !important;
  outline: none !important;
  padding: 5px 10px;
  margin-top: 10px;
  appearance: none;
  /* outline: none !important;
  text-decoration: none !important; */
}

.customArrow {
  position: absolute;
  height: 90%;
  width: 3vw;
  /* background-color: #406bdf; */
  top: 10%;
  right: 10px;
  pointer-events: none;
}

.customArrow::before,
.customArrow::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 45%;
  left: 40%;
}

.customArrow::before {
  border-left: 1vh solid transparent;
  border-right: 1vh solid transparent;
  border-top: 1.2vh solid #406bdf;
}

/* .customArrow::after {
  border-left: 1vh solid transparent;
  border-right: 1vh solid transparent;
  border-bottom: 1vh solid red;
} */

.selectOption {
  /* outline: none !important;
  border: none !important; */
  /* background-color: aqua; */
  /* border: 10px solid black;
  border-color: black; */
}

.slider {
  width: 90%;
  /* border: 1px solid purple; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  /* justify-content: center;
  align-items: center; */
}

.investment {
  width: 90%;
  /* border: 1px solid purple; */
}

.title {
  font-size: 20px;
  color: #4d4f5c;
  line-height: 1.23;
  margin-bottom: 25px;
  font-weight: normal;
  text-align: left;
}

.checkBox {
  width: 17px;
  height: 17px;
  border-radius: 3px;
  box-shadow: 0 4px 16px 0 rgba(69, 91, 99, 0.08);
  border: solid 1px #406bdf;
  background-color: #ffffff;
  text-decoration: none !important;
  outline: none;

  cursor: pointer;
}

.label {
  /* border: 1px solid purple; */
  width: 250px;
  height: 45px;
  margin: 0px 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* opacity: 0.18; */
  border-radius: 8px;
  background-color: #406adf3a;
  align-self: flex-start;
}

.tp {
  margin-top: 1vh;
  margin-left: 0.5vw;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.26px;
  text-align: left;
  color: #4d4f5c;
}
