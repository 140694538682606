@font-face {
  font-family: LatoBold;
  src: url('../../../Assets/Fonts/Lato-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: LatoM;
  src: url('../../../Assets/Fonts/Lato-Regular.ttf');
  font-display: swap;
}

.sidebar_inner {
  width: 256px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 400;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  padding: 10% 10% 30% 10%;
  display: flex;
  width: 205px;
  height: 47px;
  justify-content: space-between;
  align-items: flex-start;
}
.headingText {
  color: #0c4090;
  font-weight: 800;
  margin-left: 12px;
}
.img {
  height: 40px;
  width: 40px;
  margin-right: 2px;
}

.sidebar_menu {
  list-style: none;
  text-decoration: none;
  margin-left: -20px;
  overflow-y: scroll;
  margin-right: 20px;
  padding-right: 60px;
}

.sidebar_menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 5px;
}

.sidebar_menu::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 5px;
  margin-left: 30px;
}

.sidebar_menu::-webkit-scrollbar-thumb {
  background-color: #416ce1;
  border-radius: 10px;
}

.sidebar_menu li {
  display: flex;
  justify-content: flex-start;
  padding: 6% 0%;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: none;
  line-height: 1.2;
  letter-spacing: normal;
  color: #31333e;
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: #31333e;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: LatoM;
  outline: none !important;
}
.link:hover {
  color: #191a1d;
  text-decoration: none;
  font-family: LatoBold;
}

.bold {
  font-size: 16px;
  font-family: LatoBold;
  /* color: #31333e; */
  color: #416ce1;
}

.img1 {
  width: 100%;
  height: 100%;
}

.text {
  font-family: LatoM;
}

.icon {
  width: 30.1px;
  height: 30.5px;
  margin-right: 6px;
}

.hamburger {
  display: none;
}
@media (max-width: 768px) {
  .sidebar_inner {
    display: none;
  }
  .hamburger {
    display: block;
  }
}

@media (max-width: 1120px) {
  .sidebar_inner {
    width: 200px;
  }
}
