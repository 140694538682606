.emailEditorDiv {
  margin-top: 10px;
  width: 100%;
}

.emailEditor {
  width: 100%;
}

.buttonsDiv {
  text-align: center;
  justify-content: center;
}

.button {
  margin: 20px;
}

.spin {
  margin-left: 200px;
}

.modal {
  overflow: hidden;
  height: 50vw;
  max-height: 50vw;
  align-items: center;
  justify-content: center;
}

.emailUsersModal {
  overflow: hidden;
  height: 50vw;
  max-height: 50vw;
  align-items: center;
  justify-content: center;
  max-width: 70vw;
  width: 70vw;
}

.list {
  max-height: 22vw;
  overflow-y: scroll;
}

.allUsersDiv {
  align-items: stretch;
}

.allUsersButton {
  float: right;
}

.allUsers {
  font-size: 17px;
  font-weight: bold;
}

.input {
  margin: 20px;
}
