.Btn {
  border-radius: 5px;
  background-color: #406bdf;
  color: #fff;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  border: none;
  transition: all ease-out 0.3s;
  width: 171px;
  height: 38px;
  margin-top: 15px;
}

.Btn:hover {
  transform: scale(1.04);
}

.Btn:focus {
  outline: none;
}

@media (max-width: 768px) {
  .Btn {
    width: 131px;
    height: 33px;
    margin-top: 2px;
  }
}
