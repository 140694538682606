@font-face {
  font-family: sspb;
  src: url("../../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.expandHeader {
  height: 8vh;
  max-height: 70px;
}

.expandHeader:hover {
  cursor: pointer;
}

.expandedItems {
  display: flex;
  justify-content: center;
}

.expandedContainer {
  width: 100%;
}

.circle {
  height: 12px;
  width: 12px;
  border-radius: 6px;
}

.centerCol {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  max-height: 70px;
}

.topLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  max-height: 70px;
}

.moneyLeft {
  font-family: sspb;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 8vh;
  max-height: 70px;
}

.dropIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomLeft {
  display: flex;
  align-items: flex-start;
  font-family: sspb;
}

@media (max-width: 576px) {
  .moneyLeft {
    font-size: 18px;
    height: 6vh;
  }
  .topLeft {
    font-size: 18px;
    height: 6vh;
  }
  .circle {
    height: 10px;
    width: 10px;
    border-radius: 5px;
  }
  .expandedContainer {
    width: 100%;
  }
  .centerCol {
    height: 6vh;
  }
  .expandHeader {
    height: 6vh;
    transition: all 200ms;
  }
  .dropIcon {
    justify-content: flex-start;
  }
}
