@font-face {
  font-family: ssp;
  src: url("../../Assets/Fonts/SourceSansPro-Regular.otf");
  font-display: swap;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.innerContainer {
  width: 90%;
}

.password {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  border: 0.5px solid #406bdf;
  height: 5vh;
  font-size: 19px;
  padding-right: 50px;
  padding-left: 15px;
  font-family: Lato;
  max-height: 40px;
}

.password:focus {
  outline: none;
}

.inputDiv {
  position: relative;
}

.show {
  cursor: pointer;
  font-family: ssp;
  color: #43425d;
  position: absolute;
  right: 10px;
  top: 25%;
  font-size: 14px;
}

.msg {
  font-family: ssp;
  padding: 2px 0px 0px 10px;
}
