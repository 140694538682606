@font-face {
  font-family: LatoBO;
  src: url('../../../Assets/Fonts/Lato-Bold.ttf');
}

@font-face {
  font-family: sspb;
  src: url('../../../Assets/Fonts/SourceSansPro-Regular.otf');
}

.container {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  flex: 1;
  display: flex;
  align-items: center;
}

.left {
  flex: 1;
  display: flex;
  padding: 0px 0px 0px 30px;
  font-family: LatoBO;
  font-size: 17px;
  color: #393939;
  align-items: center;
}

.right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0px 30px 10px 0px;
  font-size: 22px;
  font-family: sspb;
}

.headerContainer {
  width: 100%;
}

.SearchHeader {
  font-size: 20px;
  font-family: LatoBO;
  padding: 10px 20px;
}

.msgBoxCont {
  height: 100%;
  width: 100%;
  padding: 20px;
  padding-top: 40px;
}

.msgBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d8d8d8;
  padding: 20px 10px;
  color: #7c7c7c;
}

.tableContainer {
  flex: 7;
  height: 100%;
}

.rowContainer {
  /* overflow: auto; */
  max-height: 200px;
}

.loader {
  height: 100%;
  display: grid;
  place-items: center;
}

.searchCont {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 20px;
}

.afterDecimal {
  font-size: 14px;
  padding-top: 8px;
}

.picker {
  display: none;
}

@media (max-width: 576px) {
  .picker {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .tp {
    display: flex;
    flex: 1;
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
  .container{
    height: 80%;
  }
}
