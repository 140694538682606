.sidebar_inner {
  width: 30%;
  position: fixed;
  box-shadow: 1px 0 15px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 400;
  animation: sliding 0.4s ease-out;
}
@keyframes sliding {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.closeBtn {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
}
.closeBtn:focus {
  outline: 0;
}
.logo {
  padding: 7% 5%;
  display: flex;
  justify-content: center;
}
.headingText {
  color: #0c4090;
  font-weight: 600;
}
.sidebar_menu {
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 10px;
}
.bold {
  color: #406bdf;

  font-size: 16px;
  font-family: LatoBold;
  padding-top: 7px;
  border-top: 2px solid #406bdf;
}

.sidebar_menu li {
  display: flex;
  justify-content: flex-start;
  padding: 5% 0%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: none;
  line-height: 1;
  letter-spacing: normal;
  color: #31333e;
}

.link {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  text-decoration: none;
  cursor: pointer;
  color: #1e2133;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: none !important;
  margin-left: 35px;
}
.link:hover {
  font-weight: bold;
  color: #31333e;
  text-decoration: none;
}
.img {
  height: 40px;
  width: 200px;
  margin-right: 2px;
}

.img1 {
  width: 100%;
  height: 100%;
}
.icon {
  width: 30.1px;
  height: 30.5px;
  margin-right: 6px;
}

.searchDiv {
  width: 230px;
  margin: 0px 5px;
}

.temp {
  margin-left: 0px;
}

@media only screen and (max-width: 768px) {
  .sidebar_inner {
    width: 40%;
  }
}

@media only screen and (max-width: 668px) {
  .sidebar_inner {
    width: 35%;
  }
}

@media only screen and (max-width: 576px) {
  .sidebar_inner {
    min-width: 60%;
    max-width: 70%;
    width: auto;
  }
}

@media (max-width: 390px) {
  .sidebar_inner {
    width: 60%;
  }
}
