@font-face {
  font-family: Lato;
  src: url("../../../Assets//Fonts//Lato-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: LatoB;
  src: url("../../../Assets/Fonts/Lato-Bold.ttf");
  font-display: swap;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: none;
  z-index: 999;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-width: 350px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.363);
}

.Modal:focus {
  outline: 0;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-backdrop-filter: blur(2.3px);
  backdrop-filter: blur(2.3px);
  background-color: #7070702a;
  z-index: 999;
}

@supports not (backdrop-filter: none) {
  .Overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}

.header {
  font-size: 20px;
  font-family: LatoB;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
}

.image {
  text-align: center;
}

.content {
  padding: 10px;
}

.buttonCont {
  display: flex;
}

.cancel {
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #bcbec6;
  color: #ffffff;
  border: 0;
  text-align: center;
  font-size: 14px;
  flex: 1;
}

.confirm {
  border-radius: 5px;
  background-color: #416ce1;
  color: #ffffff;
  border: 0;
  text-align: center;
  font-size: 14px;
  padding: 5px 20px;
  flex: 1;
  margin-left: 25px;
}

@media (max-width: 576px) {
  .Modal {
    padding: 5px;
    width: 330px;
  }
}
