@font-face {
  font-family: sspb;
  src: url("../../../Assets/Fonts/SourceSansPro-Semibold.otf");
  font-display: swap;
}

.expandHeader {
  height: 8vh;
  max-height: 70px;
  background-color: rgb(209, 28, 28);
  transition: all 200ms;
  width: 100%;
  min-height: 50px;
}

.expandHeader:hover {
  background-color: #b1b1b1;
  cursor: pointer;
}

.expandedItems {
  display: flex;
  justify-content: center;
}

.circle {
  height: 12px;
  width: 12px;
  border-radius: 6px;
}

.centerCol {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  max-height: 70px;
  min-height: 50px;
}

.topLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 17px;
  max-height: 70px;
  min-height: 50px;
  font-family: sspb;
}

.moneyLeft {
  font-family: sspb;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 8vh;
  max-height: 70px;
  /* background-color: teal; */
  min-height: 50px;
}

.dropIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: yellowgreen; */
}

.afterDecimal {
  font-size: 14px;
  padding-top: 5px;
}

.expandedContainer {
  width: 100%;
}

@media (max-width: 1440px) {
  .topLeft {
    font-size: 18px;
  }

  .circle {
    height: 8px;
    width: 8px;
    border-radius: 4px;
  }
  .afterDecimal {
    padding-top: 1.5px;
  }
}

@media (max-width: 576px) {
  .topLeft {
    font-size: 16px;
  }

  .moneyLeft {
    font-size: 17px;
  }
}
