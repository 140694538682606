.container {
  width: 100%;
}

.toggle_button {
  height: 24px;
  width: 32px;
  margin: 10px;
  background-color: transparent;
  justify-content: space-around;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
}
.toggle_button:focus {
  outline: none;
}

.toggle_button_line {
  background-color: #406bdf;
  width: 32px;
  height: 3px;
  border-radius: 5px;
}

.main {
  top: 0;
  text-align: center;
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  z-index: 2;
  transition: all 0.6s ease-out;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
  height: auto;
  min-height: 75px;
}

.Check {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.Check:focus ~ .burger {
  outline: 3px solid rgba(lightblue, 0.75);
}

.burger {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 24px;
  width: 32px;
  margin: 10px;
  background-color: transparent;
  justify-content: space-around;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
}

.burger span::before,
.burger span::after {
  content: "";
  position: absolute;
  color: #406bdf;
}

.burger span::before {
  bottom: 7px;
  color: #406bdf;
}

.burger span::after {
  top: 7px;
  color: #406bdf;
}

.temp {
  position: absolute;
  text-align: center;
  top: 100%;
  left: 0;
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: all 400ms ease-in-out;
  background-color: #ffffff;
}

.logo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.actualLogo {
  margin-top: 2vh;
  margin-bottom: 10px;
  width: 180px;
}

.navBtn {
  display: none;
}

.unordered {
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 400ms;
}

.listItem {
  margin-bottom: 10px;
  margin-left: 2vw;
}

.itemLink {
  color: #1e2133;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  opacity: 1;
  transition: opacity 150ms ease-in-out;
  text-decoration: none !important;
}

.Check:checked ~ .temp {
  transform: scale(1, 1);
}

.Check:checked ~ .itemLink {
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

.active,
.itemLink:hover {
  transition: 0.5s;
}

.burger span,
.burger span::before,
.burger span::after {
  display: block;
  background: black;
  height: 2px;
  width: 2em;
  border-radius: 2px;
  position: relative;
}

.myTp {
  width: 90%;
}

@media screen and (min-width: 1210px) {
  .burger {
    display: none;
  }

  .main {
    display: grid;
    grid-template-columns: 0.5fr auto minmax(600px, 3fr) 0.2fr;
    height: 90px;
    margin-top: -8px;
    transition: all 0.6s ease-out;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
  }

  .logo {
    grid-column: 1 / 2;
    justify-content: flex-end;
  }

  .actualLogo {
    width: 80%;
    margin-bottom: 10px;
  }

  .navBtn {
    display: none;
  }

  .temp {
    position: relative;
    text-align: right;
    transition: none;
    transform: scale(1, 1);
    background: none;
    top: initial;
    left: initial;
    grid-column: 3 /4;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .unordered {
    display: flex;
    align-items: center;
  }

  .listItem {
    margin-bottom: 0;
  }

  .itemLink {
    opacity: 1;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none !important;
    outline: none !important;
  }

  .myTp {
    margin-left: 0;
    width: 280px;
    margin-bottom: 7px;
  }

  .itemLink:hover::before {
    transform: scale(1, 1);
  }
}

.caresAct {
  font-weight: bold;
  cursor: pointer;
  font-size: 13px;
}
