.Outer {
  width: 90%;
  height: 90%;
  padding: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.innerText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 400;
}
.afterDecimal {
  font-size: 14px;
  padding-top: 7.5px;
}
.pieCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92%;
  width: 90%;
}

@media (max-width: 1024px) {
  .innerText {
    font-size: 12px;
  }
  .afterDecimal {
    font-size: 11px;
    padding-top: 7.5px;
  }
}
