@font-face {
  font-family: sspb;
  src: url("../../../Assets//Fonts/SourceSansPro-Semibold.otf");
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../../../Assets//Fonts/Lato-Regular.ttf");
  font-display: swap;
}

.dropDownContainer {
  height: auto;
  background-color: #f8f8f8;
  border-bottom: 1px solid #c6cbde;
}

.centerCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topLeft {
  height: auto;
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  color: #416ce1;
  font-family: sspb;
  padding: 10px 0px;
  white-space: -moz-pre-wrap; /* Mozilla */
  white-space: -hp-pre-wrap; /* HP printers */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
  word-break: break-all;
}

.leftWrapper {
  display: flex;
  justify-content: center;
}

.bottomLeft {
  height: auto;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: #404a57;
  padding-bottom: 15px;
  font-family: sspb;
}

.topRight {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
  font-family: sspb;
}

.bottomRight {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  font-size: 14px;
  color: #404a57;
  font-family: sspb;
}

.afterDecimal {
  font-size: 13px;
  padding-top: 3px;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

@media (max-width: 1440px) {
  .circle {
    height: 8px;
    width: 8px;
    border-radius: 4px;
  }
  .bottomRight {
    padding-right: 40px;
    font-size: 12px;
  }
  .topRight {
    padding-right: 40px;
    font-size: 18px;
  }
  .afterDecimal {
    font-size: 14px;
    padding-bottom: 1.5px;
  }
  .topLeft {
    font-size: 14px;
  }
  .bottomLeft {
    font-size: 12px;
    padding-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .dropDownContainer {
    max-height: 150px;
    padding: 10px 0px;
  }
  .bottomRight {
    align-items: center;
    font-size: 13px;
  }
  .topRight {
    max-height: 50px;
    padding-right: 40px;
    font-size: 17px;
  }
  .afterDecimal {
    font-size: 14px;
    padding-top: 3px;
  }
  .topLeft {
    max-height: 50px;
    align-items: center;
    font-size: 14px;
    padding: 10px;
  }
  .bottomLeft {
    align-items: center;
    font-size: 12px;
    padding-bottom: 5px;
  }
}
