@font-face {
  font-family: sspb;
  src: url("../../../Assets/Fonts/SourceSansPro-Semibold.otf");
  font-display: swap;
}

.toolTip {
  color: #535353;
  border-radius: 5px;
  border: 1px solid rgb(97, 97, 97);
  background-color: white;
  padding: 5px;
  font-size: 15px;
  font-family: sspb;
}

.money {
  font-family: sspb;
  color: #2cb65a;
}

.xVal {
  font-family: sspb;
}

.chartCont {
  height: 100%;
  padding-top: 20px;
  padding-right: 20px;
}

.afterDecimal {
  font-size: 12px;
}

.smallmoney {
  font-family: sspb;
  font-size: 14px;
}

.smalltoolTip {
  color: #535353;
  border-radius: 5px;
  border: 1px solid rgb(97, 97, 97);
  background-color: white;
  padding: 5px;
  font-size: 13px;
  font-family: sspb;
}

@media (max-width: 576px) {
  .toolTip {
    color: #535353;
    border-radius: 5px;
    border: 1px solid rgb(97, 97, 97);
    background-color: white;
    padding: 5px;
    font-size: 13px;
    font-family: sspb;
  }
  .smallmoney {
    font-family: sspb;
    font-size: 13px;
  }
  .afterDecimal {
    font-size: 10px;
  }
}
