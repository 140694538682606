.genderContainer {
  width: 40%;
  height: 40%;
}
.selectBox {
  width: 100%;
  height: 40%;
  border: solid 0.5px #406bdf;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 16px 0 rgba(69, 91, 99, 0.08);
  text-decoration: none !important;
  outline: none;
  padding: 5px;
  /* appearance: none; */
  
}

.optgroup {
  font-size: 12px;
}

.container {
  padding: 10px;
}

@media(max-width:576px) {
  .container {
    padding: 10px 0px;
  }
  .selectBox {
    font-size: 12px;
  }
}